@import "styles/color.scss";
@import "styles/layout.scss";

.textContent {
  p {
    font-family: ProximaNova;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
  }
}

.addGroupFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: $WHITE;
  float: right;
  margin-bottom: 12px;
}

.container {
  width: 675px;

  .pageHeading {
    font-family: ProximaNova;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    .pageNumber {
      font-family: DINPro;
    }

    .totalPages {
      font-family: DINPro;
      color: var(--whoop-color-gray-500);
      padding-right: var(--spacing-xs-4);
    }
  }

  .pageDesc {
    font-family: ProximaNova;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1%;
  }

  .input {
    padding-top: var(--spacing-sm-5);
  }
}