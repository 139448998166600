
.pageSelector {
  background: #f5f5f5;
  border: none;
  border-radius: 8px;
  align-items: center;

  input[type="radio"]:checked + label {
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      margin: 2px 2px;
      border: none;
  }
}