.pillarStatTile:first-of-type {
  margin-left: 0px;
}

.pillarStatTile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  min-width: 200px;
  background: white;
  margin-top: 10px;
  margin-left: 16px;
  border-radius: 4px;

  padding: 12px;
  border: double 2px transparent;

  &.pointer {
    cursor: pointer;
  }

  &.pointer:hover {
    &:not(.selectedTile) {
      border: solid 2px var(--color-gray-600);
    }
  }

  &.loadingAnimation {
    animation: loadingColor 1s infinite alternate;
    background-repeat: no-repeat;
  }

  @keyframes loadingColor {
    from {
      background-color: var(--whoop-color-gray-200);
    }
    to {
      background-color: var(--whoop-color-gray-300);
    }
  }


  &.selectedTile {
    border: double 2px transparent;
    border-radius: 4px;
    background-image: linear-gradient(white, white), linear-gradient(var(--color-gray-600), white);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    // These pseudo elements help create the inverted border radius
    // We are essentially masking a rounded elemnt ontop of a white background
    // the negative px help position it directly between the bottom edge of the card and the tile itself
    &::after,
    &::before {
      content: "";
      position: absolute;
      background: var(--whoop-color-gray-200);
      width: 10px;
      height: 36px;
      -webkit-background-clip: padding-box;
      bottom: -34px;
      z-index: 5;
    }

    &::before {
      left: -12px;
      -webkit-border-bottom-right-radius: 8px;
      -moz-border-radius-bottomright: 8px;
    }

    &::after {
      right: -12px;
      -webkit-border-bottom-left-radius: 8px;
      -moz-border-radius-bottomleft: 8px;
    }
    ////////////////////////////////////////////////////

    .selected {
      position: absolute;
      height: 37px;
      width: 243px;
      background-color: #fff;
      bottom: -36px;
      left: -10px;
      z-index: 0;
    }
    .titleContainer {
      .title {
        stroke: var(--whoop-color-black);
      }

      .selected {
        position: absolute;
        height: 32px;
        width: 243px;
        background-color: #fff;
        bottom: -32px;
        left: -10px;
        z-index: 0;
      }
    }
  }

  &:not(.selectedTile) {
    .titleContainer {
      .icon > path {
        fill: var(--whoop-color-black-alpha-500);
      }

      .title {
        color: var(--whoop-color-black-alpha-500);
      }
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .icon {
      width: 32px;
      height: 32px;
      stroke-width: 1.5px;
      overflow: inherit;

      path {
        fill: var(--whoop-color-black);
      }
    }
  }

  .valueContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .currentValueRow {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end;

      .currentValue {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        font-family: DinPro;
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 50px;
        margin: 0px;

        .percent {
          font-family: DinPro;
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 28px;
          margin: 0px;
        }
      }
    }

    .average {
      font-family: DinPro;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      margin: 0px;
      color: var(--whoop-color-black-alpha-300);
      padding-top: 8px;
    }
  }
}
