@import '@whoop/web-components/dist/typography.mixin';

.tick {
  font-family: "DINPro";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.insightsTick {
  @include n6;
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  text-transform: uppercase;
}

.yAxisTick {
  font-family: "DINPro";
  font-weight: 700;
  font-size: 12.64px;
  line-height: 16px;
}

.percentOfMax {
  fill: var(--color-strain-blue);
}

.percentOfMax,
.heartRateAxis,
.durationAxis {
  font-family: "DINPro";
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
}

.dayMonthAxisTick {
  font-weight: 700;
  font-size: 14px;
  font-family: "DINPro";
  line-height: 14px;
}

.strainRecoveryTick .tick {
  font-weight: 700;
  font-size: 14px;
  font-family: "DINPro";
  line-height: 14px;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.profileIconOpacity {
  opacity: 50%;
}

