@import '@whoop/web-components/dist/typography.mixin';

.themeActivitiesTable.tabs {
  gap: 8px;

  .tab {
    width: 100%;
  }

  button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.themeNavigation.tabs {
  height: calc(100% - 1px);
  color: var(--color-white);

  .tab {
    height: 100%;
    color: var(--whoop-color-gray-600);

    &:hover:not(.activeTab) {
      border-bottom: none;
    }

    button {
      color: inherit;
      padding: 0px 24px;
      font-size: 15px;
      letter-spacing: 0.1em;
    }
  }

  .activeTab {
    border-color: var(--color-white);
    color: var(--color-white);
  }
}

.themeHeader.tabs {
  height: 100%;

  .tab {
    height: 100%;
    color: var(--whoop-color-black-alpha-500);

    button {
      color: inherit;
      font-size: 13px;
      letter-spacing: 0.1em;
    }
  }

  .activeTab {
    color: var(--color-black);
  }
}

.tabs {
  display: flex;
  gap: 32px;

  .tab {
    height: 44px;
    display: flex;

    &:hover:not(.activeTab) {
      border-bottom: var(--color-gray-600) 2px solid;
    }

    button {
      font-family: inherit;
      padding: 0px 8px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14.22px;
      line-height: 16px;
      display: flex;
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .activeTab {
    border-bottom: var(--color-black) 2px solid;
  }

  .badge {
    background: var(--color-gray-200);
    border-radius: 24px;
    padding: 0px 12px;
    height: 24px;
    line-height: 24px;
    margin-left: 8px;
    font-size: 12.64px;
  }
}

.themeTeams.tabs {
  height: 68px;
  color: var(--text-color);
  gap: 32px;

  .tab {
    height: 100%;
    color: var(--tab-text-color);

    &:hover:not(.activeTab) {
      border-bottom: none;
    }

    button {
      color: inherit;
      @include b1;
      padding: 0px;
    }
  }

  .activeTab {
    border-color: var(--text-color);
    color: inherit;
  }
}
