@use "styles/_color.scss";
@use "styles/_layout.scss";
@import url("https://use.typekit.net/nnt8fft.css");

@font-face {
  font-family: ProximaNova;
  font-weight: 400;
  src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3");
}

@font-face {
  font-family: Proxima Nova;
  font-weight: 400;
  src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3");
}

@font-face {
  font-family: Jude-Medium;
  font-weight: 700;
  src: url("assets/fonts/Jude-500-Medium.woff") format("woff");
}

@font-face {
  font-family: DinPro;
  font-weight: 700;
  src: url("assets/fonts/DINPro-Bold.woff") format("woff");
}

body {
  margin: 0;
  font-family: "ProximaNova-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;
  width: inherit;
}

.App {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
