@import "styles/color";
@import "styles/layout";

.collabTable {
  width: 100%;
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 137px;
    z-index: 100;
    background-color: $WHITE;
    tr:first-child {
      border-bottom: 1px solid var(--color-black-alpha-100);
    }
  }

  th,
  td {
    position: relative;
    padding: 0 12px;
  }

  tbody > tr {
    border-bottom: 1px solid var(--color-black-alpha-100);
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
    letter-spacing: .1px;
    text-transform: none;
    align-items: center;
  }

  button > p {
    display: flex;
  }
}

.grayText {
  color: $TEXT_OVERRIDES_2;
}

.tableContainer {
  min-width: 500px;
  width: 100%;
  margin-bottom: $SPACING-8;
}



.collabTableBody {
  tr {
    height: 80px;
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: $SPACING-3;
  font-weight: normal;
}

.paginationSection {
  display: flex;
  justify-content: center;
  align-items: center;

  .rowSelect {
    margin: 20px 0 30px 0;
  }
}


