.pillarPoint {
  font-weight: 700;
  font-size: 17px;
  font-family: "DINPro";
  line-height: 21px;
}

.avatar {
  width: 100%;
  height: 100%;
}
