.topCardsContainer {
  margin-top: 24px;
  margin-bottom: 24px;
  min-height: 350px;
}

.bottomCardsContainer, .activityContainer {
  min-height: 350px;
  height: 100%;
  margin-bottom: 24px;
}