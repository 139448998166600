.pillarPieChart {
  height: 200px;
  width: 200px;
  margin: 0 auto;

  font-family: 'DINPro';
  font-weight: 700;
  font-size: 62px;
  line-height: 88px;
  letter-spacing: -0.02em;
  color: var(--whoop-color-black);

  svg text {
    transform: translate(0px, -7px);
  }
}