@import "styles/color";
@import "styles/layout";

.tableContainer {
  background: var(--whoop-color-white);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  width: 100%;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
}

.linkBtn {
  display: flex;

  img {
    margin-right: $SPACING-1;
  }
}

.tableControlButtons {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: auto;

  gap: $SPACING-2;

  button {
    white-space: nowrap;
    transition: none;
  }
}

.blueBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: fit-content;
  height: 40px;
  background: #0093E7;
  border: 1px solid #089BE4;
  border-radius: 4px;
  color: white;
  margin: 0 8px;
}

.whiteBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: fit-content;
  height: 40px;
  color: #0093E7;
  border: 2px solid #089BE4;
  border-radius: 4px;
  margin: 0 8px;
}

.logo {
  margin-left: 12px;
  width: 10px;
  height: 10px;
  margin-top: 2px;
}

.control {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .leftControl {
    display: flex;
    align-items: center;
  }
  .rightControl {
    display: flex;
  }
}

.item {
  text-transform: capitalize;
  font-family: var(--whoop-font-family-bold);
  margin-left: 0px;
}
