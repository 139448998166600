.tile {
  width: 148px;
  height: 135px;
  background-color: var(--whoop-color-white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    width: 38px;
    height: 32px;
    stroke-width: 1.5px;
    overflow: inherit;

    path {
      fill: var(--whoop-color-black-alpha-500);
    }
  }

  .filledIcon {
    stroke-width: 0.1px;
  }

  .stat {
    font-family: var(--whoop-font-family-normal);
    font-weight: 700;
    font-size: 14.22px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: var(--whoop-color-black-alpha-500);
    margin: 8px;
  }

  .value {
    font-family: var(--dinpro-font-family-bold);
    font-weight: 700;
    font-size: 36.49px;
    line-height: 40px;
    color: var(--whoop-color-black-alpha-900);
  }
}
