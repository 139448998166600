@import "styles/color.scss";
@import "styles/layout";

.fullPageModal {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: $WHITE;

  .modalContent {
    max-width: 700px;
    padding-top: 120px;
    padding-bottom: 100px;

    h4 {
      color: var(--whoop-color-black-alpha-500);
    }

    p {
      margin: 10px 0;
    }

    > p {
      margin: 32px 0;
    }
  }

  .modalButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $WHITE;
  }
}
