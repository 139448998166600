@import "styles/color.scss";

.container {
  width: 336px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;

  .actionBtn {
    display: flex;
    color: $STRAIN_BLUE;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0px 20px;
    height: 44px;

    .btnIcon {
      width: 20px;
      height: 20px;
      margin: 8px 8px;
    }

    .btnText {
      font-family: ProximaNova;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  .content {
    font-family: ProximaNova;
    padding: 16px 16px 0px;
    color: rgba(0, 0, 0, 0.7);

    h3 {
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin: 8px 0px;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      margin: 8px 0px;
      width: 303px;
    }

    img {
      width: 31px;
      height: 30px;
      border-radius: 16px;
    }
  }
}
