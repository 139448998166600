.activitiesTable {
  height: 368px;
  display: flex;
  flex-direction: column;

  .navBar {
    margin-top: -8px;
    border-bottom: var(--whoop-color-black-alpha-100) 1px solid;
    margin-bottom: 8px;
  }

  .sectionsWrapper {
    height: 100%;
    overflow-y: auto;
  }

  .tableViewRow {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    border-radius: 4px;
    overflow: hidden;
    transition: max-height .3s ease-in-out, background .3s, margin-bottom .3s;


    &[aria-expanded='true'] {
      background: var(--color-white);
      margin-bottom: 8px;

      .arrowIcon {
        transform: rotate(-180deg);
      }
    }

    .rowHeader {
      display: flex;
      border: none;
      background: none;
      font-family: inherit;
      align-items: center;
      padding: 0;
      font-size: 16px;
      line-height: 20px;
      padding: 0px 8px;
      text-transform: capitalize;

      .rowTitle {
        font-weight: 400;
      }

      .rowDetail {
        font-weight: 700;
        color: var(--color-gray-700);
        text-align: left;
        width: 44px;
      }

      .blueText {
        color: var(--color-strain-blue);
      }

      .arrowIcon {
        width: 16.25px;
        height: 36px; // Row Height
        color: var(--color-gray-500);
        margin-left: auto;
        transition: transform .3s;
        cursor: pointer;
      }

    }

    .rowContentWrapper {

      .rowContent {
        display: flex;
        padding: 0px 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        height: 36px;
        align-items: center;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          background: var(--whoop-color-black-alpha-50);
        }

        &:first-child {
          border-top: var(--whoop-color-gray-200) 1px solid;
        }

        &:last-child {
          padding-bottom: 4px;
        }

        .rowContentTitle {
          padding-left: 44px;
        }

        .rowContentDetail {
          margin-left: auto;
          color: var(--color-strain-blue);
        }
      }
    }
  }
}



