@import "styles/color.scss";

.infoText {
  width: 336px;

  h1 {
    color: $WHITE;
    font-size: 28px;
    line-height: var(--spacing-sm-4);
    font-weight: 400;
  }

  p {
    font-size: 14px;
    color: #969696;
    font-family: var(--whoop-font-family-bold);
  }
}

.passwordWrap {
  display: flex;
  flex-direction: column;
  position: relative;

  .showPasswordButton {
    color: #c7c7c7;
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-align: right;
    text-transform: uppercase;
    position: absolute;
    top: 28px;
    right: 16px;
    user-select: none;
    background: none;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;


    &:hover {
      cursor: pointer;
    }
  }
}

.formContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 336px;
  justify-content: flex-start;
  margin-top: 40px;

  .passwordWrap {
    margin-bottom: 24px;
  }

  .inputField,
  .passwordWrap .inputField {
    color: $WHITE;
    border: 2px solid transparent;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -1px 0px #ffffff;
    height: var(--spacing-sm-4);
    width: auto;
    outline: none;
    padding: 0 0 0 10px;
    margin-bottom: var(--spacing-xs);
    margin-top: var(--spacing-xs);

    &:focus-visible {
      border: 2px solid $STRAIN_BLUE;
      outline: none;
    }
  }

  .inputFieldInvalid {
    background: rgba(255, 0, 38, 0.2);
    box-shadow: inset 0px -1px 0px #ff0026;
  }

  .invalidMessage {
    border-left: 2px solid transparent;
    color: $BRANDING_RED;
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
    height: 16px;
    margin-bottom: 12px;
  }

  input {
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
  }

  .inputFieldLabel {
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
    line-height: var(--spacing-xs-3);
    letter-spacing: 0.1em;
    text-transform: uppercase;

    margin: 0;
    width: auto;
    text-align: left;

    color: rgba(255, 255, 255, 0.5);

    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  button {
    display: flex;
    margin-top: var(--spacing-md-2);
    margin-bottom: var(--spacing-md-2);
    width: 100%;
    justify-content: center;
    align-self: center;
    border-radius: 4px;
    margin-top: 0;
  }

  button:disabled {
    background: rgba(255, 255, 255, 0);
    outline: #969696;
    outline-style: solid;
  }
}

.requestAccess {
  color: white;
  position: relative;
  bottom: 60px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  text-align: center;

  a {
    color: white;
  }
}

.passwordStrength {
  margin-top: 0;
  font-size: 12px;
  font-family: var(--whoop-font-family-normal);

  .level {
    color: $WHITE;
    font-weight: 600;
    margin: 0;
  }

  div {
    p {
      display: inline-block;
      margin: var(--spacing-xs) 0 var(--spacing-xs) var(--spacing-xs);
      color: #9b9292;
    }
  }
}


.checkEmail {
  display: flex;
  flex-direction: column;
  .checkEmailInfoText {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    width: 336px;
  
    h1 {
      color: $WHITE;
      font-size: 28px;
      line-height: 35px;
      font-weight: 400;
      margin-bottom: 0;
    }
  
    p {
      margin-bottom: 40px;
      font-family: var(--whoop-font-family-bold);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #969696;
      text-align: center;
    }
  
    img {
      width: 60px;
      margin-bottom: 52px;
    }
  }

  button {
    margin-top: var(--spacing-xs-3);
    display: flex;
    border-radius: 4px;
    align-self: center;
    justify-content: center;
  }
}

.checkEmailReturnToSignIn {
  color: white;
  border-color: white;
  width: 100%;
}