@import "styles/color.scss";

.actionFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $WHITE;
}

.progressDots {
  display: flex;
  gap: var(--spacing-xs-2);
  justify-content: space-between;
  position: absolute;
  left: 50%;
}

.blackDot {
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 8px;
  margin-top: 8px;
}

.grayDot {
  width: 8px;
  height: 8px;
  background-color: #000000;
  opacity: 0.2;
  border-radius: 8px;
  margin-top: 8px;
}

.primaryButton, .secondaryButton {
  border-radius: 4px;
  display: flex;

  p {
    display: block;
    font-size: 13px;
    font-weight: bold;
  }
}

.primaryButton {
  p {
    margin: 0 10px 0 0;
  }
}

.secondaryButton {
  p {
    margin: 0 0 0 10px;
  }
}