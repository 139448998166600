@import "styles/color";
@import "styles/layout";

.pagination {
  display: flex;
  align-content: flex-end;
  justify-content: center;
  margin: 20px 0 30px 0;
  line-height: 2;
  height: $SPACING-5;

  .button {
    margin: 0 10px 0 10px;
    border: none;
    background: none;
    cursor: pointer;
  }

  .button:disabled,
  .button[disabled] {
    cursor: default;
    opacity: 0.3
  }
}

.paginationValues {
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.paginationArrows {
  height: 20px;
  width: 20px;
  padding: 10px;
  color: var(--svg-color);
}

.circleBackground {
  height: 40px;
  width: 40px;
  &:hover {
    background-color: $GRAY_300;
    border-radius: 50%;
  }
}