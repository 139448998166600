@import '@whoop/web-components/dist/typography.mixin';

.widgetContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: var(--color-background-blue);
  border-radius: 12px;
}

.expandedSpacing {
  padding: 16px 16px 0px 16px;
  box-sizing: border-box;
}

.loading {
  padding: 16px 16px 0px 16px;
  height: 130px;
  box-sizing: border-box;
}

.expandableCardContent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .expandedContent {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
    width: 50%;
    padding: 16px 0px 16px 16px;

    .graphDescription {
      height: 64px;
      @include c1;
      display: flex;
      align-items: center;
    }

    .addBackground {
      box-sizing: border-box;
      padding: 4px 16px;
      background: var(--whoop-color-white-alpha-50);
      border-radius: 8px;
    }

    .logoIcon {
      height: 44px;
      width: 44px;
      margin-right: 12px;
    }

    .graphTitleLine {
      color: var(--whoop-color-white-alpha-500);
      @include c2;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      margin-top: 8px;

      .title {
        letter-spacing: 1.1px;
        text-transform: uppercase;
        font-weight: 700;
      }

      .averageLine {
        color: var(--whoop-color-white);
        margin-right: 8px;
      }
    }

    .groupMembers {
      @include c2;
      color: var(--whoop-color-white-alpha-500);
      letter-spacing: 1.1px;
      text-transform: uppercase;
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
    }

    .trainingZoneLabel {
      margin-top: 0px;
    }
  }

  .condensedContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    box-sizing: border-box;
    width: 50%;

    // we want our widgets to all have the same height regardless of the content inside them
    // i.e. the strain breakdown graph is larger than the other pillars, but the widget height is still uniform
    // in order to achieve this the height of the content under the header must be defined
    &.messageHeight {
      min-height: 240px;
    }
    &.contentHeight {
      height: 452px;
    }

    .breakdownChart {
      display: flex;
      padding: 16px;

      box-sizing: border-box;
      height: 100%;
      background-color: var(--color-black-alpha-200);

      &.expandedBreakdownChart {
        border-radius: 4px;
        border-top: 1px solid var(--color-black-alpha-100);
        border-bottom: 1px solid var(--color-black-alpha-100);
      }
    }
  }
}

.leaderboard {
  overflow: hidden;
  box-sizing: border-box;
  margin: 16px 8px 0px 16px;
  flex-grow: 1;
}

.loadingLeaderboard {
  margin: 16px;
}



.message {
  padding: 12px 16px;
  box-sizing: border-box;
  height: 100%;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .icon {
    color: var(--color-white-alpha-500);
    height: 28px;
    width: 28px;
  }

  .messageText {
    font-weight: 600;
    color: var(--color-white-alpha-500);
    @include p5;
  }
}
