.activityHrCard {
  display: flex;
  flex-direction: column;
  height: 440px;
  min-width: 400px;
  flex-grow: 1;
  margin-left: 16px;

  .activityHRGraph {
    height: 100%;
    min-height: 0px;
    width: 100%;
  }
}