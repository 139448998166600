.profileContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .profileHeader {
    background-color: var(--color-white);
    position: sticky;
    opacity: 1;
    top: 0;
    padding: 0px;
    z-index: 100;
  }

  .profileHeaderTopRow {
    padding-top: 32px;
  }

  .datePickerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    max-width: 100%;

    position: relative;
  }

  .profileBreakdownOptionContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-top: 24px;
    border-bottom: 1px solid var(--color-black-alpha-100);

    .optionTabButton {
      cursor: pointer;
      text-decoration: none;
      background-color: transparent;
      border: none;

      &.active {
        border-bottom: 2px solid var(--color-strain-blue);

        & > button {
          color: var(--color-strain-blue);
        }
      }
    }
  }
}

.datePickerBox {
  top: 0px;
  margin-top: 50px;
  right: 0px;
}

.singleDaySelected {
  right: 230px;
}