.loadingAnimation {
  position: relative;
  border-radius: 8px;
  height: 100%;
  box-sizing: border-box;
  flex-grow: 1;

  animation: loadingColor 1s infinite alternate;
  background-repeat: no-repeat;
}

@keyframes loadingColor {
  from {
    background-color: #1f292f;
  }
  to {
    background-color: #2d3940;
  }
}

