.loader {
    display: none;
    left: 50%;
    overflow-x: hidden;
    padding-top: 4px;
    top: 50%;
    position: relative;
    transform: translate(-50%,-50%);
    &:before {
      animation:left-to-right 2s infinite linear;
      background-image:linear-gradient(to right, transparent 0%, #0e5b87 25%, var(--whoop-color-strain-blue) 50%, #0e5b87 75%, transparent 100%);
      content:"";
      height:4px;
      left:0;
      overflow:hidden;
      position:absolute;
      right:0;
      top:0;
    }

    &.visible {
        display: revert;
    }
  }
  
  @keyframes left-to-right {
    0% {
      transform:translateX(-100%);
    }
    100% {
      transform:translateX(100%);
    }
  }