.profileWrapper {
  background: var(--color-white);
  width: 708px;
  height: 680px;
  left: calc(50vw - 354px);
  text-align: left;
  font-weight: normal;
  cursor: default;

  .button {
    background: none;
    border: none;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: var(--color-gray-300);
    }
  }

  .profileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    padding: 24px 24px 10px;
    background: var(--color-white);

    .userInfoHeader {
      font-family: "Proxima Nova";
      font-weight: 600;
      font-size: 20.25px;
      line-height: 24px;
    }
  }

  .bodyWrapper {
    padding: 0px 24px;
  }

  .closeIcon {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
  }

  .subtitle {
    font-size: 12.64px;
    line-height: 16px;
    color: var(--whoop-color-gray-700);
  }

  .mainContent {
    font-size: 16px;
    line-height: 20px;
    color: var(--whoop-color-black-alpha-900);
  }

  .userInformation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;

    .userInfoSection {
      max-width: 322px;

      .subtitle {
        padding-bottom: 4px;
      }
    }
  }

  .groups {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .groupsHeader {
      padding-top: 32px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }

    .groupSection {
      border: var(--whoop-color-black-alpha-200) 1px solid;
      border-radius: 8px;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 24px;
      }

      .trashIcon {
        svg {
          width: 19.5px;
          height: 22.5px;
        }
      }

      .mainContent {
        padding-bottom: 4px;
      }
    }
  }
}
