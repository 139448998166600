@import '@whoop/web-components/dist/typography.mixin';

.breadcrumbs {
  @include p4;
  font-weight: 600;
  color: var(--text-color);
  display: flex;
  align-items: center;
  height: 36px;

  button {
    all: unset;
  }

  .clickableBreadcrumb {
    text-decoration: underline;
    cursor: pointer;
  }

  .slash {
    font-weight: 600;
    font-size: 21px;
    color: var(--whoop-color-white-alpha-300);
    margin-left: 12px;
    margin-right: 12px;
  }

  .breadcrumb {
    display: flex;
  }
}