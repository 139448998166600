// Spacing
$SPACING-0: 4px;
$SPACING-1: 8px;
$SPACING-2: 16px;
$SPACING-3: 24px;
$SPACING-4: 32px;
$SPACING-5: 40px;
$SPACING-6: 48px;
$SPACING-7: 56px;
$SPACING-8: 64px;
$SPACING-9: 88px;
