@import "styles/color.scss";

.feedbackMessage {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 336px;
  text-align: center;
  justify-content: center;

  h1 {
    color: $WHITE;
    font-size: 28px;
    font-weight: 400;
    font-family: var(--whoop-font-family-normal);
  }

  p {
    font-size: 14px;
    color: #969696;
    font-family: var(--whoop-font-family-bold);
  }

  img {
    margin: auto;
    height: 60px;
    width: 60px;
    margin-bottom: 25px;
  }

  button {
    margin-top: 50px;
    background: rgba(255, 255, 255, 0);
    outline: $WHITE;
    outline-style: solid;
    font-weight: 700;
    width: 100%;
  }
}

.tosTxt {
  text-align: center;
  font-family: var(--whoop-font-family-bold);
  color: #969696;
}

.infoText {
  width: 336px;

  h1 {
    color: $WHITE;
    font-size: 28px;
    line-height: 35px;
    font-weight: 400;
  }

  p {
    font-size: 15px;
    color: #969696;
    font-family: var(--whoop-font-family-bold);
  }
}

.emailWrap {
  display: flex;
  flex-direction: column;
  position: relative;

  input:disabled {
    color: #969696;
  }

  .lockIcon {
    position: absolute;
    color: #c7c7c7;
    top: 25px;
    right: 15px;
  }
}

.passwordStrength {
  margin-top: 0;
  font-size: 12px;
  font-family: var(--whoop-font-family-normal);

  .level {
    color: $WHITE;
  }

  div {
    p {
      display: inline-block;
      margin: var(--spacing-xs) 0 var(--spacing-xs) var(--spacing-xs);
      color: #9b9292;
    }
  }
}

.passwordWrap {
  display: flex;
  flex-direction: column;
  position: relative;

  .showPasswordButton {
    color: #c7c7c7;
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-align: right;
    text-transform: uppercase;
    position: absolute;
    top: 28px;
    right: 15px;
    user-select: none;
    background: none;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.formContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 20rem;
  justify-content: center;

  .inputField,
  .passwordWrap .inputField {
    color: $WHITE;
    border: 2px solid transparent;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -1px 0px #ffffff;
    height: 36px;
    width: 19rem;
    outline: none;
    padding: 0 0 0 10px;
    margin-bottom: 4px;

    &:focus-visible {
      border: 2px solid $STRAIN_BLUE;
      outline: none;
    }
  }

  .inputFieldInvalid {
    background: rgba(255, 0, 38, 0.2);
    box-shadow: inset 0px -1px 0px #ff0026;
  }

  .invalidMessage {
    border-left: 2px solid transparent;
    color: $BRANDING_RED;
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
    height: 16px;
    margin-bottom: 12px;
  }

  input,
  select,
  textarea {
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
  }

  .inputFieldLabel {
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: contents;

    margin: 0 0 6px 0;
    width: auto;
    text-align: left;

    color: rgba(255, 255, 255, 0.5);
  }

  button {
    display: flex;
    margin-top: 54px;
    margin-bottom: 54px;
    width: 100%;
    justify-content: center;
    align-self: center;
  }

  button:disabled {
    background: rgba(255, 255, 255, 0);
    outline: #969696;
    outline-style: solid;
  }
}

.profileImageWrap {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: #a6a6a6;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 132px;
  height: 132px;
  left: 655px;
  top: 580px;

  .profileImageSaved {
    border-radius: 50%;
    width: 132px;
    height: 132px;
    left: 655px;
    top: 580px;
  }

  .editIcon {
    position: absolute;
    color: $WHITE;
  }

  .profileImageUpload {
    display: none;
  }

  .profileImageSaved:hover {
    opacity: 0.5;
  }

  span {
    display: block;
    text-transform: uppercase;
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
  }
}

.profileImageWrap:hover {
  cursor: pointer;
  color: $WHITE;
}

.profileImageModalDialog {
  display: flex;
  justify-content: center;

  span {
    text-transform: uppercase;
    font-size: 18px;
    font-family: var(--whoop-font-family-bold);
  }
}

.profileImageModalContent {
  display: flex;
  border-radius: 50%;
  border: 5px solid #212121;
  width: 290px;
  height: 290px;
  left: 568px;
  top: 375px;
}

.center {
  display: flex;
  justify-content: center;
}

.countryListItem {
  maxHeight: 10rem;
}
