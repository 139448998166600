@import "styles/color";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .messageContainer {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  img {
    width: 70px;
    height: 70px;
    margin-bottom: 44px;
  }

  p {
    margin-top: 32px;
    color: $TEXT_OVERRIDES_2;
  }

  button {
    margin-top: 48px;
  }
}
