@import '@whoop/web-components/dist/typography.mixin';

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  box-sizing: border-box;
  height: 48px;
  padding: 8px;

  &.expandedStyle {
    border-bottom: 1px solid var(--color-white-alpha-100);
    background-color: var(--color-black-alpha-50);
  }

  .title {
    @include p5;
    font-family: var(--whoop-font-family-semibold);
    padding-left: 8px;
  }
}

.actionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  padding: 0;

  background: var(--color-white-alpha-50);
  /* Tones/White/White 10 */

  border: 1px solid var(--color-white-alpha-100);
  border-radius: 4px;

  // make hover state cover full button
  &:not([disabled]):hover:before {
    transform: scale(4.5);
  }
  &:not([disabled]):focus:before {
    transform: none;
  }

  .icon {
    fill: var(--color-white);
    color: var(--color-white);
    height: 12px;
    width: 12px;
  }
}
