.requestProgress {
  width: 100%;
  height: 5px;
  position: relative;
  overflow: hidden;
  border-bottom: none;

  .bar {
    position: absolute;
    width: calc(100% * 2 / 7);
    height: 5px;
    display: flex;
    animation: move 1s linear infinite;
    background: linear-gradient(90deg, #0e5b87 0%, #0093e7 100%);
    padding: 0px;

    @keyframes move {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(250%, 0);
      }
    }
  }
}

.hide {
  visibility: hidden;
}