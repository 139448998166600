.overlayContainer,
.fullPageContainer {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
}

.fullPageContainer {
  background-color: #1a2227;
}
