@import "styles/color.scss";

.forgotPassword {
  width: 336px;
}

.infoText {
  display: flex;
  flex-direction: column;

  h1 {
    color: $WHITE;
    font-size: 28px;
    line-height: 35px;
    font-weight: 400;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 40px;
    font-family: var(--whoop-font-family-bold);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #969696;
  }
}

.checkEmailReturnToSignIn {
  color: white;
  border-color: white;
  width: 100%;
}

.checkEmailInfoText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  width: 336px;

  h1 {
    color: $WHITE;
    font-size: 28px;
    line-height: 35px;
    font-weight: 400;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 40px;
    font-family: var(--whoop-font-family-bold);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #969696;
    text-align: center;
  }

  img {
    width: 60px;
    margin-bottom: 52px;
  }
}

.emailWrap {
  display: flex;
  flex-direction: column;
  position: relative;
}

.formContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;

  .inputField {
    display: flex;
    color: $WHITE;
    border: 2px solid transparent;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -1px 0px #ffffff;
    height: 36px;
    width: 320px;
    outline: none;
    padding: 0 0 0 10px;
    margin-bottom: 4px;
    margin-top: 4px;

    &:focus-visible {
      border: 2px solid $STRAIN_BLUE;
      outline: none;
    }
  }

  .inputFieldInvalid {
    background: rgba(255, 0, 38, 0.2);
    box-shadow: inset 0px -1px 0px #ff0026;
  }

  .invalidMessage {
    border-left: 2px solid transparent;
    color: $BRANDING_RED;
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
    height: var(--spacing-xs-4);
    margin-bottom: var(--spacing-xs-3);
  }

  .inputFieldLabel {
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
    line-height: var(--spacing-xs-3);
    letter-spacing: 0.1em;
    text-transform: uppercase;

    margin: 0 0 var(--spacing-xs) 0;
    width: auto;
    text-align: left;

    color: rgba(255, 255, 255, 0.5);
  }

  .buttonWrap {
    display: flex;
    flex-direction: column;
    margin-top: 52px;
    justify-content: space-between;
    width: 100%;

    .resetLink {
      margin-bottom: 12px;
      border-radius: 4px;
    }

    .returnToSignIn {
      color: white;
    }
  }

  button {
    display: flex;
    justify-content: center;
    margin-bottom: var(--spacing-md);
  }

  button:disabled {
    background: rgba(255, 255, 255, 0);
    outline: var(--color-gray-700);
    outline-style: solid;
  }
}

.checkEmail {
  display: flex;
  flex-direction: column;

  button {
    margin-top: var(--spacing-xs-3);
    display: flex;
    border-radius: 4px;
    align-self: center;
    justify-content: center;
  }
}