@import "styles/theming.scss";
@import '@whoop/web-components/dist/typography.mixin';

.teamsContainer {
  flex-grow: 1;
  background-color: var(--background-color);
  padding-bottom: 168px;
  
  .header {
    // Tabs height is 68px + 1px for active tabe border
    height: 69px;
    background-color: var(--background-color);
    color: var(--text-color);

    .tabsWrapper {
      display: flex;
      justify-content: space-between;
      .mxpSectionWrapper {
        background: var(--whoop-color-background-blue);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 10px;
        margin-top: 20px;

        a:link, a:visited {
          color: var(--whoop-color-strain-blue);
        }

        .attentionIcon {
          height: 20px;
          width: 20px;
        }
      }
    }

  }

  .breadcrumbsRow {
    padding-top: 20px;
    padding-bottom: 8px;
    background-color: var(--background-color);
    position: sticky;
    top: 0px;
    z-index: 100;
  }

  .actionMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }

  .breadcrumbsRowWithGroup {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .comparisonDateRow {
    padding-top: 8px;
    text-align: right;
    padding-bottom: 16px;
    position: sticky;
    // date picker height + breakcrumb row padding = 36px + 20px + 8px = 64px
    top: 64px;
    background-color: var(--background-color);
    z-index: 10;

    .comparisonVs {
      @include c2;
      color: var(--whoop-color-white-alpha-500);
    }

    .comparisonIcon {
      height: 8px;
      width: 8px;
      margin-right: 4px;
    }
  }
}
