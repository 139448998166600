.arrow {
  padding-left: 4px;
  width: 14px;
  height: 12px;
}

.green {
  stroke: var(--color-teal);
  color: var(--color-teal);
}

.red {
  stroke: #ffa722;
  color: #ffa722;
}

.rectangle {
  width: 14px;
  height: 8px;
  margin-bottom: 4px;
  margin-left: 4px;
  background-color: var(--color-gray-700);
}
