@import "styles/color";
@import "styles/layout";

.linkBtn {
  display: flex;

  img {
    margin-right: $SPACING-1;
  }
}

.logo {
  margin-left: 12px;
  width: 10px;
  height: 10px;
  margin-top: 2px;
}

.itemLabel {
  font-family: var(--whoop-font-family-normal);
  cursor: pointer;
  text-transform: capitalize;
}