.activeItem {
  .itemLabel {
    font-family: var(--whoop-font-family-bold);
    margin: 0 0 0 15px;
  }
}
.inactiveItem {
  .itemLabel {
    margin: 0 0 0 39px;
  }
}

.itemLabel {
  font-family: var(--whoop-font-family-normal);
  cursor: pointer;
  text-transform: capitalize;
}
