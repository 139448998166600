@import "styles/color.scss";
@import "styles/layout.scss";

.messageContainer {
  margin-top: 100px;
}

.inviteFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $WHITE;

  .availableMemberships {
    p {
      margin: 4px 0;
    }
    .upgradeText {
      cursor: pointer;
      font-family: var(--whoop-font-family-semibold);
      color: var(--whoop-color-strain-blue);
      text-decoration: underline;
    }
  }
}
