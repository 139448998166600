.formFields {
    margin-top: 40px;
    width: 720px;
}

.title {
    margin-bottom: 24px;
}

.overTwo {
    color: #C8C8C8;
    margin-right: 4px;
}

.collaboratorDetailsTable {
    width: 720px;
    margin-left: 0px;

    th {

        h5 {
            text-align: left;
        }
    }

    td {

        p {
            font-weight: 600;
            font-size: 14px ;
            line-height: 18px ;
            color: rgba(0, 0, 0, 0.7);
            align-items: center;
            font-family: ProximaNova;
            font-style: normal;
            margin: 0;
            letter-spacing: .1px;
            text-transform: none;
        }

        .dropdownBtn {
            padding: 0 0 0 8px;
            text-transform: capitalize;
            margin-left: -12px;
            height: 100%;
        }

        .privacyLevel {
            text-transform: none;
            display: flex;
            align-items: center;
        }

        button > p {
            display: flex;
        }
    }
}

.dropdownBtn {
    height: 100%;
}

.columnHeader {
    margin-left: 24px;
}

.privacyLevelDropdown {
    display: flex;
    margin-left: 20px;
}

.itemLabel {
    font-family: ProximaNova;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
}

.itemDescription {
    font-family: ProximaNova;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #969696;
    white-space: normal;
}

.activeItem {
    .itemLabel {
        margin-left: 16px;
        margin-bottom: 4px;
        margin-top: 4px;
    }
    .itemDescription {
        margin-left: 16px;
        margin-bottom: 4px;
        margin-top: 4px;
    }
}

.inactiveItem {
    .itemLabel {
        margin-left: 40px;
        margin-bottom: 4px;
        margin-top: 4px;
    }
    .itemDescription {
        margin-left: 40px;
        margin-bottom: 4px;
        margin-top: 4px;
    }
}
