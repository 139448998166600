.zoneDurationsBox {
  height: 440px;
  width: 317px;

  .sleepZoneDurationsChart {
    margin-top: 64px;
    height: 222px;
    width: 100%;
  }

  .strainZoneDurationsChart {
    margin-top: 20px;
    height: 338px;
    width: 100%;
  }
}