@import "styles/color.scss";
@import "styles/layout.scss";

.tableHeader {
  width: 100%;
  margin-left: 8px;

  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  margin-top: $SPACING-5;

  .heading {
    margin-bottom: $SPACING-0;
  }
  .subtext {
    color: $TEXT_OVERRIDES_2;
  }

  .selectAll {
    color: var(--whoop-color-strain-blue);
  }
}
