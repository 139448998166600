@import "styles/color";
@import "styles/layout";

.content {
    width: 100%;
    height: 100%;
    max-width: 1550px;
    p {
        margin-top: 8px;
    }
}

.tableContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    margin-top: 16px;
}

.tableControlBar {
    display: flex;
    height: 40px;
    margin: $SPACING-2 0 12px 0;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 8px;
}

.blueBtn {
    color: $WHITE;
    background: $STRAIN_BLUE;
    border: 1px solid $STRAIN_BLUE;
}

.tableControlButtons {
    display: flex;
    gap: 8px;

    svg {
        padding-right: 8px;
    }
}

.tabContainer {
    width: 100%;
    height: 44px;
    display: flex;
    border-bottom: 1px solid #EBEBEB;
    align-items: center;
}

.searchBar {
    width: 30%;
}

.error {
    margin: 24px 0;
}

.selectedRow {
    background-color: var(--color-strain-blue-alpha-100);
}

.stickyHeader {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    padding-bottom: 16px;
}
