.cardContainer {
  display: flex;
  flex-direction: column;

  .placeholderContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .icon {
      height: 60px;
      width: 60px;
      & > path {
        fill: var(--whoop-color-black-alpha-300);
      }
      margin-bottom: 8px;
    }

    .text {
      margin: 0;
      margin-bottom: 8px;

      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 600;
      font-size: 1.424rem;
      line-height: 28px;
    }

    .subtext {
      margin: 0;
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 1.226rem;
      line-height: 24px;
      color: var(--whoop-color-black-alpha-500);
    }
  }
}
