.noDataInfo {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #ffdde2;
  color: #ff0026;
  height: 44px;
  margin-top: 24px;
  border-radius: 4px;
  font-family: ProximaNova;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  .noDataIcon {
    width: 15px;
    height: 15.5px;
    margin-right: 8px;
  }
}