.pageHeading {
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  .pageNumber {
    font-family: DINPro;
  }

  .totalPages {
    font-family: DINPro;
    color: var(--whoop-color-gray-500);
    padding-right: var(--spacing-xs-4);
  }
}
