.label {
  display: flex;
  flex-direction: row;
  align-items: center;

  .labelSquare {
    width: 10px;
    height: 10px;
    margin-right: 14px;
  }

  .labelCount {
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    font-family: var(--whoop-font-family-normal);
    color: var(--whoop-color-black);
    margin-right: 12px;
    width: 28px;
  }

  .labelName {
    font-family: ProximaNova;
    font-size: 14.22px;
    line-height: 16px;
    color: var(--whoop-color-black);
    letter-spacing: 0.5px;
    margin-right: 4px;
    margin-top: 4px;
  }

  .labelDescription {
    font-family: ProximaNova;
    font-size: 12.64px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: var(--whoop-color-black-alpha-500);
    margin-top: 4px;
  }
}
