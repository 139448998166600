@import '@whoop/web-components/dist/typography.mixin';

.pieChartIcon {
  svg {
    color: var(--whoop-color-gray-600);
  }
}

.pieChartLabels {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.breakdownTitle {
  @include c1;
  font-weight: 700;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  .labelSquare {
    width: 8px;
    height: 8px;
    margin-right: 8px;
  }

  .labelCount {
    @include n5;
    color: var(--whoop-color-white);
    margin-right: 2px;
    line-height: 20px;
  }

  .icon {
    width: 12px;
    height: 12px;
    margin-right: 12px;
  }

  .labelName {
    @include c1;
    color: var(--whoop-color-gray-500);
  }
}