@import "styles/color";
@import "styles/layout";
@import '@whoop/web-components/dist/typography.mixin';

:export {
  selectedColor: $GRAY_300;
  unselectedColor: $WHITE;
  loadingColor: $GRAY_500;
}

.memberEmail {
  color: rgba(0, 0, 0, 0.5);

  &.loading {
    color: $GRAY_500;
  }
}

.editCellContainer {
  height: 40px;
  align-items: center;
  display: flex;
  width: 100%;
}

.editCell {
  display: flex;
  align-items: center;
  width: 100%;
}

.editCellInput {
  margin: 8px 0 8px 8px;
  padding-left: 8px;
  width: min-content;
  height: 44px;

  display: flex;
  flex-direction: row;
  align-items: center;

  height: 44px;
  background: #F5F5F5;

  border: 1px solid #0093E7;
  border-radius: 4px;
  min-width: 120px;
  max-width: 220px;
}

.editIcon {
  color: rgba(0, 0, 0, 0.7);
  padding: 8px;
  height: 16px;
  width: 16px;
}

.editCellContainerHover {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 44px;
  border-radius: 4px;
  padding-left: 8px;
}

.editCellContainerHover:hover {
  background: #F5F5F5;
}

.columnHeader {
  margin-left: 8px;
}

.dropdownStatic::first-letter {
  text-transform: capitalize;
}

.dropdownStatic {
  margin-left: 28px;
}

.editCellText {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}


.avatarCell {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs-4);
  text-transform: capitalize;

  .avatar {
    display: inline-flex;
    align-items: center;
  }

  .avatarSize {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .largerAvatarSize {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.pictureContainer {
  display: flex;
  height: 80px;

  img {
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 12px;
  }

  .textContainer {
    margin: auto 0;
  }
}

.concealedIconRow {
  display: flex;
  align-items: center;

  .concealedIcon {
    margin-left: 16px;
    height: 20px;
    width: 20px;
  }
}

.textCell {
  text-align: left;
  font-weight: 500;
  padding-left: 12px;
}

.iconContainer {
  padding: 18px 36px;
  display: flex;
  justify-content: center;

  .icon {
    height: 12px;
    width: 12px;
    color: var(--color-black-alpha-700);
  }
}

.hideAverageCell {
  padding-inline-start: 20px;
}

.widgetAvatar {
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .widgetLeaderboardName {
    @include c2;
    line-height: 16px;
    font-weight: 700;
    color: var(--whoop-color-white);
    text-align: left;
  }
}

.groupLandingCell {
  @include c1;
  text-align: left;
  margin-left: 24px;
  gap: 8px;
  font-weight: 500;
  max-width: 256px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .numMembersRow {
    display: flex;
    @include c2;
    color: var(--whoop-color-white-alpha-700);
    margin-top: 8px;

    .communityIcon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}

.infoCompSize {
  width: 20px;
  height: 20px;
  color: var( --color-gray-700);
}

.tooltipContent {
  width: 247px;
  padding: 6px;
  white-space: pre-line;
  color: var(--whoop-color-gray-700);
  @include c2;
  text-align: left;
}

