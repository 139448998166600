@mixin insightsScrollbar{
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--whoop-color-white-alpha-100) var(--whoop-color-background-blue);

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 4px;
    padding-left: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--whoop-color-background-blue);
    border-radius: 0px 8px 8px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--whoop-color-white-alpha-100);
    border-radius: 3px;
  }
}
