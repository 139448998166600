[data-theme='light'] {
  --text-color: var(--whoop-color-black);
  --background-color: var(--whoop-color-white);
  --background-color-hover: var(--$GRAY_200);
  --border-color: var(--whoop-color-black-alpha-500);
  --svg-color: var(--whoop-color-black);
  --search-background-color: var(--whoop-color-black-alpha-100);
  --search-background-color-hover: var(--whoop-color-black-alpha-200);
  --search-placeholder: #757575;
  --search-border: var(--whoop-color-black-alpha-500);
  --tab-text-color: var(--whoop-color-black);
  --overlay-background: var(--whoop-color-black-alpha-100);
  --date-picker-button-background: var(--whoop-color-white);
  --date-picker-text: var(--whoop-color-black);
  --date-picker-background: var(--whoop-color-white);
  --date-picker-grey-text: var(--whoop-color-black-alpha-500);
  --date-picker-border-line: var(--whoop-color-black-alpha-100);
  --date-picker-day-hover: var(--whoop-color-black-alpha-50);

  --date-picker-background-color: var(--color-black-alpha-100);
  --date-picker-text-color: var(--color-black-alpha-900);
  --date-picker-input-selected-border: var(--color-black);
  --date-picker-input-hover-color: var(--color-black-alpha-200);
  --date-picker-modal-background-color: var(--color-white);
  --date-picker-selected-day-hover: rgb(0, 132, 208);

  --quick-select-background-color: var(--color-white);
  --quick-select-text-color: var(--color-black-alpha-900);
  --quick-select-hover-text-color: var(--color-black-alpha-900);
  --quick-select-hover-background-color: var(--color-black-alpha-50);
  --quick-select-selected-hover-text-color: var(--whoop-color-white-alpha-900);
  --quick-select-selected-hover-background-color: var(--whoop-color-black-alpha-700);
  --quick-select-selected-background-color: var(--color-black-alpha-900);
  --quick-select-selected-text-color: var(--whoop-color-white-alpha-900);

  --date-picker-secondary-text-color: var(--color-black-alpha-500);
  --date-picker-selected-date-dot-color: var(--color-strain-blue);
  --date-picker-selected-date-background-color: var(--color-strain-blue-alpha-100);
  --date-picker-date-hover-background-color: var(--color-black-alpha-100);
  --date-picker-date-hover-border-color: var(--color-black-alpha-300);
  --date-picker-disabed-day: var(--color-black-alpha-200);
  --date-picker-button-background-navigation: var(--color-black-alpha-100);
  --date-picker-button-background-hover-navigation: var(--color-black-alpha-50);
  --date-picker-disabled-button-background-navigation: var(--color-black-alpha-50);
  --date-picker-disabled-caret-icon: var(--color-black-alpha-200);
  --date-picker-today-dot-color: var(--color-white-alpha-900);
  --date-picker-selected-text-color: var(--color-white-alpha-900);
  --date-picker-date-hover-color: var(--color-black-alpha-900);
}

[data-theme='dark'] {
  --text-color: var(--whoop-color-white);
  --background-color: var(--whoop-color-background-darkblue);
  --background-color-hover: var(--whoop-color-white-alpha-50);
  --border-color: var(--whoop-color-white-alpha-500);
  --svg-color: var(--whoop-color-white);
  --search-background-color: var(--whoop-color-white-alpha-100);
  --search-background-color-hover: var(--whoop-color-white-alpha-200);
  --search-placeholder: var(--whoop-color-white-alpha-500);
  --search-border: var(--whoop-color-white-alpha-500);
  --tab-text-color: var(--whoop-color-gray-700);
  --overlay-background: var(--whoop-color-white-alpha-100);
  --date-picker-button-background: var(--whoop-color-white-alpha-100);
  --date-picker-text: var(--whoop-color-white-alpha-900);
  --date-picker-background: var(--whoop-color-background-blue);
  --date-picker-grey-text: var(--whoop-color-white-alpha-500);
  --date-picker-border-line: var(--whoop-color-white-alpha-100);
  --date-picker-day-hover: var(--whoop-color-white-alpha-50);

  --date-picker-background-color: var(--color-white-alpha-100);
  --date-picker-text-color: var(--color-white-alpha-900);
  --date-picker-input-selected-border: var(--color-white);
  --date-picker-input-hover-color: var(--color-white-alpha-200);
  --date-picker-modal-background-color: var(--color-background-blue);
  --date-picker-selected-day-hover: rgb(25, 158, 233);

  --quick-select-background-color: var(--color-white-alpha-100);
  --quick-select-text-color: var(--color-white-alpha-900);
  --quick-select-hover-text-color: var(--color-white-alpha-900);
  --quick-select-hover-background-color: var(--color-white-alpha-200);
  --quick-select-selected-hover-text-color: var(--whoop-color-black-alpha-900);
  --quick-select-selected-hover-background-color: var(--whoop-color-white-alpha-700);
  --quick-select-selected-background-color: var(--color-white-alpha-900);
  --quick-select-selected-text-color: var(--whoop-color-black);

  --date-picker-secondary-text-color: var(--color-white-alpha-500);
  --date-picker-selected-date-dot-color: var(--color-strain-blue);
  --date-picker-selected-date-background-color: var(--color-strain-blue-alpha-100);
  --date-picker-date-hover-background-color: var(--color-white-alpha-100);
  --date-picker-date-hover-border-color: var(--color-white-alpha-300);
  --date-picker-disabed-day: var(--color-white-alpha-200);
  --date-picker-button-background-navigation: var(--color-white-alpha-100);
  --date-picker-button-background-hover-navigation: var(--color-white-alpha-200);
  --date-picker-disabled-button-background-navigation: var(--color-white-alpha-50);
  --date-picker-disabled-caret-icon: var(--color-white-alpha-200);
  --date-picker-today-dot-color: var(--color-white-alpha-900);
  --date-picker-selected-text-color: var(--color-white-alpha-900);
  --date-picker-date-hover-color: var(--color-white-alpha-900);
}
