.offlineIcon {
    color: #FFA722;
    width: 12px;
    height: 12px;
    margin: 0 4px;
}

.statusBadge{
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
