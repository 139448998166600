@import "styles/color.scss";
@import "styles/layout";

.fullPage {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: $WHITE;

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $WHITE;
  }
}

.removeGroupTable {
  margin-top: 24px;
  thead {
    border-top: none;
    tr th div h4 {
        color: rgba(0, 0, 0, 0.7);
    }
  }
  tbody {
    tr {
      border: none;
      height: 64px;
    }
  }
}