@import '@whoop/web-components/dist/typography.mixin';

.content {
  max-width: 900px;

  .privacyLevelTable {
    padding: 0px;
  }

  .sectionTitle {
    @include p4;
    color: var(--whoop-color-black-alpha-500);
    margin-bottom: 20px;
  }

  .sectionSpacing {
    margin-top: 40px;
  }
}

.cardWrapper {
  width: 100%;
  background-color: var(--whoop-color-gray-100);
  margin-bottom: 14px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 14px;
  padding-top: 14px;
  border-radius: 12px;
  
  .topSection {
    display: flex;
    justify-content: space-between;
  }

  .titleCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;  
  }
  
  .description {
    @include c1;
    color: var(--whoop-color-gray-700);
    padding-right: 16px;
  }
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.settingsButton {
  padding: 12px 16px;
  border-radius: 4px;
  width: 168px;
  height: 40px;
}

.jobTitlesContainer {
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 16px;
}

.addJobTitleContainer { 
  margin-top: 16px;
  display: flex;
  align-items: center;

  .icon {
    height: 24px;
    width: 24px;
    margin-left: 8px;
    cursor: pointer;
  }
}