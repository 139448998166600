.groupContainer {
  margin-top: 12px;
  width: 100%;
}

.groupBox {
  margin-top: 12px;
  height: 44px;
  width: 100%;
  background: #F5F5F5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.groupTitle {
  color: #212121;
  margin-left: 16px;
}

.moreButton {
  margin-left: auto;
  margin-bottom: 2px;
}


.avatar {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: 32px;
}

.detailHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.subheadingText {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-top: 8px;
}

.emptyGroupContainer {
  width: 100%;
  text-align: center;
  margin-top: 20px;

  svg {
    color: #969696;
    width: 30px;
    height: 30px;
  }

  p {
    font-family: ProximaNova;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #969696;
  }
}