@import '@whoop/web-components/dist/typography.mixin';

.activityIcon {
  color: var(--whoop-color-white);
}

.clickableAnnotation {
  cursor: pointer;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 700;
  font-size: 12.64px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: capitalize;

  .durationText {
    font-family: "DINPro";
    font-size: 17px;
    line-height: 21px;
  }
}

.avgMarker {
  fill: var(--whoop-color-white);
}

.avgMarkerText {
  @include n6;
  font-size: 10px
}

.mappingLine {
  stroke: var(--whoop-color-background-blue);
  stroke-width: 2px;
  fill: none;
}

.xAxisLine {
  stroke: var(--whoop-color-white-alpha-500);
  stroke-width: 2px;
}

.lowerTrainingAdaptationBackground {
  fill: var(--whoop-color-hr-zone-blue);
}

.midTrainingAdaptationBackground {
  fill: var(--whoop-color-teal);
}

.upperTrainingAdaptationBackground {
  fill: var(--whoop-color-hr-zone-red);
}
