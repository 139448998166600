@import "styles/color";
@import "styles/layout";

.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    border-top: 1px solid var(--whoop-color-black-alpha-100);
    &:first-child {
      border-bottom: 1px solid var(--whoop-color-black-alpha-100);
    }
  }

  th,
  td {
    position: relative;
    padding: 0 12px;
  }
}

.grayText {
  color: $TEXT_OVERRIDES_2;
}

.tableContainer {
  min-width: 500px;
  width: 100%;
  margin-top: $SPACING-2;
}

.bottomMargin {
  margin-bottom: $SPACING-8;
}

.selectedRow {
  background-color: $STRAIN_BLUE;
  &:hover {
    background-color: $SLEEP_BLUE;
  }
}

.tableBody {
  .tableRow {
    cursor: pointer;
    background-color: white;
    border-bottom: 1px solid var(--whoop-color-black-alpha-100);

    .drilldownBtn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      visibility: hidden;
      height: var(--spacing-md-5);
    }
    &:hover {
      background-color: var(--whoop-color-black-alpha-50);
      .drilldownBtn {
        visibility: visible;
      }
    }
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: $SPACING-3;
  font-weight: normal;
}

.emptyTable {
  margin-top: $SPACING-5;
}

.searchBar {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: var(--spacing-sm) 0 0 0;

  > :first-child {
    width: 100%;
  }
}

.controlButtons {
  display: flex;
  flex-wrap: wrap;

  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  margin: var(--spacing-xs-4) 0;
}

.borderBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: var(--spacing-xs-5);
}

.loadingTitle {
  margin-bottom: 20px;
}

.header {
  display: inline-flex;
  align-items: center;

  > svg {
    margin: auto auto auto $SPACING-1;
    display: block;
  }
}
.unsorted {
  > svg {
    visibility: hidden;
    fill: var(--whoop-color-black-alpha-300);
  }
  &:hover {
    > svg {
      visibility: visible;
    }
  }
}
