@import "styles/color";
@import "styles/layout";

.header {
  display: inline-flex;
  align-items: center;

  > svg {
    margin: auto auto auto $SPACING-1;
    display: block;
  }
}
.unsorted {
  > svg {
    visibility: hidden;
    fill: var(--whoop-color-black-alpha-300);
  }
  &:hover {
    > svg {
      visibility: visible;
    }
  }
}
