.bannerContainer {
  position: relative;
  width: calc(100% - 80px);
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--color-notification-blue);
  padding: 16px 40px;


  .link {
    text-decoration: none;
  }

  .closeIcon {
    width: 15px;
    height: 15px;

    position: absolute;
    padding: inherit;
    right: 0;
  }
}