@import "styles/color";
@import "styles/layout";

.backgroundImage {
  position: relative;
  width: 100vw;
  background: url('../../../../src/assets/login-background.jpg');
  background-size: cover;
}

.preRegistration {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white-alpha-900);
  background: linear-gradient(0, rgba(0, 0, 0, 0) 15%, rgba(84, 103, 116, 0.4) 100%), linear-gradient(0, rgba(13, 17, 19, 0.9) 0%, rgba(0, 0, 0, 0) 100%), rgba(26, 34, 39, 0.85);
}

.preRegistrationContainer {
  max-width: 1208px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.instructionsContainerChangeEmail {
  margin-top: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 830px;
}

.instructions {
  color: var(--color-white-alpha-700);
  margin: 0 15% 0 5%;
  padding-top: 15vh;

  p {
    padding-left: 12px;
    font-size: 18px;
    &:not(:first-child) {
      padding-top: 24px;
    }

  }

  h2 {
    margin: 0;
    font-size: 28px;
    padding-bottom: 24px;
  }
}

.bringToTop {
  margin-top: 6vh;
  padding-top: 0px;
}

.logo {
  height: 52px;
  margin-bottom: 40px;
  align-self: flex-start;
}

.image {
  width: 100%;
  max-height: 800px;
  object-fit: cover;
  border-radius: 16px;
  height: calc(100vh - 200px);
  margin-top: 6vh;
  min-height: 600px;
}

.flowButtons {
  padding-top: 10%;
  margin-bottom: 24px;
  display: flex;
  gap: 16px;
  position: relative;

  button {
    width: 100%;
    height: 44px;
  }
}

.modalFlowButtons {
  padding: 0px;
  margin: 0px;
}

.hidden {
  display: none;
}

.link {
  color: var(--color-white-alpha-900);
}

.list {
  font-size: 18px;
  padding-left: 18px;
  li {
    margin-bottom: 20px;
  }
}

.linkSection {
  color: var(--whoop-color-white-alpha-900);
  font-size: 18px;
  margin-bottom: 20px;
  padding: 8px 12px;
  background: rgba(255, 167, 34, 0.1);
  border-radius: 8px;
  border: #FFA722 solid 1px;
}

.accordionContainer {
  .faq {
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 8px;
  }
  .accordion {
    p{
      padding: 0;
      font-size: 14px;
    }
    line-height: 18px;
  }

}

.stepText {
    font-size: 15px;
    color: var(--color-white-alpha-700);
    font-family: var(--whoop-font-family-bold);
    padding-left: 0 !important;
}

.bold {
  color: var(--color-white-alpha-900);
  font-weight: bold;
}

.infoTooltip {
  width: 100%;
  height: 100%;
  margin-left: auto;
  position: absolute;
  bottom: 184px;
  left: 50px;
  z-index: 20;

  .closeButton {
    background: none;
    border: none;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: var(--color-gray-300);
    }

    .closeIcon {
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
    }
  }

  .tooltipContent {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -208px;
    width: 100%;
    padding: 36px;
    right: 48px;
    z-index: 1;
    background-color: var(--whoop-color-white);
    border-radius: 8px;

    .headerText {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: ProximaNova;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: var(--whoop-color-black-alpha-900);
      margin-bottom: 20px;
      font-weight: bold;
    }

    .detailsText {
      display: flex;
      flex-direction: column;
      gap: 18px;
      white-space: pre-line;
      margin-top: 8px;
      font-family: ProximaNova;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: var(--whoop-color-black-alpha-700);
    }
  }
}

.pageWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-black-alpha-700);
  z-index: 10;
}

.signInModalWrapper {
  .bold {
    font-weight: bold;
    color: var(--color-black-alpha-900);
    font-size: 18px;
    line-height: 24px;
  }

  margin: 36px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-width: 626px;
  min-width: 426px;
}
