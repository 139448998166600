.infoItem {
  margin-top: 28px;
  margin-bottom: var(--spacing-sm);

  .itemTitle {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #969696;
    font-weight: bold;
  }

  .itemDetail {
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .itemNumberDetail {
    font-family: "DINPro";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    color: rgba(9, 19, 41, 0.99);
  }

  p {
    margin: var(--spacing-xs) 0;
  }
}
