.removeMember {
  color: var(--whoop-color-red);
  font-family: var(--whoop-font-family-normal);
}

.tableContainer {
  display: flex;
}

.controls {
  display: flex;
  align-items: center;
  width: 100%;

  .left {
    display: flex;
    flex-grow: 1;
  }

  .buttons {
    display: flex;
    gap: var(--spacing-xs-2);

    .button {
      padding: 12px 16px;
      border-radius: 4px;
      width: fit-content;
      height: 40px;
    }
  }

  .statusDropdown {
    display: inline-flex;
  }

  .logo {
    margin-left: 12px;
    width: 10px;
    height: 10px;
    margin-top: 2px;
  }

  .statusOptions {
    text-transform: capitalize;
  }
}