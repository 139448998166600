.strainRecoveryTrendGraphContainer {
  padding-bottom: 40px;
  min-height: 500px;

  .strainRecoveryTrendGraph {
    flex-grow: 1;
    height: 600px;
    z-index: 0;
  }
}

.overviewHRContainer {
  padding-bottom: 40px;
  min-height: 500px;
}

.pillarBarRow {
  height: 253.5px;
  margin-top: 16px;
  margin-bottom: 16px;
}
