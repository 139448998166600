.pillarCardsContainer {
  margin-top: 24px;
  margin-bottom: 24px;
  min-height: 350px;
}

.statTiles {
  display: flex;
  flex-direction: row;
}

.pillarBreakdownBar {
  height: 32px;
  width: 100%;
  margin-top: 24px;
}

.pillarBarCard {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
}

.labels {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
}
