@import "styles/color";
.container {
    display: flex;
    margin: auto;
    width: 688px;

    .title {
        padding-bottom: var(--spacing-md-3);
    }

    .heading {
        padding-bottom: var(--spacing-sm-2);
    }

    .subtitle {
        padding-bottom: var(--spacing-md);
    }
}

.button {
    padding: 12px 16px;
    border-radius: 4px;
    width: 92px;
    height: 40px;
}

.searchContainer {
    display: relative;
    width: 688px;

    .controlContainer {
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--spacing-xs-4);

        .bulkAction {
            display: flex;
            justify-content: flex-end;
        }
    }

    ul {
        position: absolute;
        z-index: 1;
        width: 688px;
        list-style-type: none;
        box-shadow: 0px 0px var(--spacing-xs-2) rgba(0, 0, 0, 0.14902);
        border-radius: var(--spacing-xs);
        padding-inline-start: 0;
        background-color: var(--whoop-color-white);

        li {
            padding: var(--spacing-xs) var(--spacing-xs-2) 0 var(--spacing-sm-5);

            &:hover {
                background-color: var(--whoop-color-gray-200);
                cursor: pointer;
            }

            div {
                display: inline-flex;
                align-items: center;
                width: 100%;
            }

            span {
                white-space: nowrap;
                padding-left: var(--spacing-xs-2);
                padding-right: var(--spacing-xs-2);
            }

            .name {
                padding-left: var(--spacing-xs-2);
                font-family: var(--whoop-font-family-normal);
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: #212121;
                text-transform: capitalize;
            }

            .email {
                font-family: var(--whoop-font-family-normal);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }

            .serial {
                display: inline-flex;
                justify-content: flex-end;
                padding-right: 10%;
                flex-grow: 1;
                font-family: "DINPro";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
            }

            .privacyLevel {
                text-transform: capitalize;
                display: inline-flex;
                justify-content: flex-end;
                padding-right: 10%;
                flex-grow: 1;
                font-family: ProximaNova;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }

            .avatar {
                height: 26px;
                width: 26px;

                img {
                    width: 26px;
                    height: 26px;
                    border-radius: 20px;
                }
            }
        }
    }

    .hide {
        display: none;
    }
}
