@import "styles/layout.scss";

.accountsContainer {
  margin: 0 auto;
  width: 80%;
  max-width: 1550px;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.pageHeader {
  margin-top: var(--spacing-md-4);
  width: 552px;

  h3 {
    margin-bottom: 24px;
  }
}

.tableContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 80px;
}

.accountFooterContainer {
  min-height: 100vh;
  position: relative;
}
