.searchIcon {
    position: absolute;
    padding: var(--spacing-xs-3);
    width: 20px;
    height: 20px;
}

.fuzzySearchInput {
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
    background-color: var(--whoop-color-gray-200);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: var(--spacing-xs);
    padding: var(--spacing-xs-3) 0 var(--spacing-xs-3) var(--spacing-sm-4);
}
