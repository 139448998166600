.group {
    max-width: 100%;
    padding: var(--spacing-md-3);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.tableContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.table {
  width: 90%;
  display: flex;
  flex-grow: 1;
  margin: 0 44px;
}