@import "styles/color";
@import "styles/layout";

.tableHeader {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;

  .heading {
    margin-bottom: $SPACING-0;
  }
  .subtext {
    color: $TEXT_OVERRIDES_2;
  }

  .selectAll {
    color: var(--whoop-color-strain-blue);
  }

  .downloadIconContainer {
    background-color: var(--whoop-color-gray-200);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
}

.membersRow {
  display: flex;
  flex-direction: row;
}
