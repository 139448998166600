.content {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 93%;
  height: 100%;
  padding-left: 48px;
  padding-top: 56px;

  .pageHeader {
    width: 552px;
    margin-bottom: var(--spacing-sm-5);

    h3 {
      margin-bottom: var(--spacing-sm-5);
    }
  }

  .wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .sectionContainer {
      margin: var(--spacing-xs-3) 0;
      width: 45%;
      min-width: 400px;

      h2 {
        margin-bottom: var(--spacing-sm-5);
      }

      .infoItem {
        margin-bottom: var(--spacing-sm);
        p {
          margin: var(--spacing-xs) 0;
        }
      }
    }

    .sectionItem {
      display: flex;
      align-items: center;
      margin-top: var(--spacing-md);

      .buttonWrap {
        width: 126px;
        margin-right: var(--spacing-xs-5);
      }
    }
  }
}

.alertContainer {
  margin: 12px 0;
}

.activeTab {
  margin-bottom: -13px;
  border-bottom: 2px solid #000000;

  button {
    color: rgba(0, 0, 0, 0.9);;
  }
}

.tabs {
  height: 67px;
  display: flex;
  border-bottom: 1px solid #EBEBEB;
  padding-left: 24px;
  align-items: center;
}

.componentContainer {
  display: flex;
  flex-direction: row;
}
