.card {
  padding: 16px 16px 32px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: var(--whoop-color-white);

  &.overflow {
    // to allow horizontal scrolling on overflow
    overflow-x: auto;
    overflow-y: hidden;
  }

  .cardHeaderContainer {
    display: flex;
    flex-direction: row;

    &.stickyHeader {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .cardHeader {
    font-family: ProximaNova;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--whoop-color-black);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cardDetails {
    font-family: ProximaNova;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--whoop-color-black-alpha-500);
    margin-top: 4px;
    margin-bottom: 16px;
    text-align: left;
  }

  .tooltipContainer {
    margin-left: auto;
  }

  .chipContainer {
    border-left: 1px solid var(--whoop-color-black-alpha-100);
    margin-left: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .activities {
      gap: 8px;
      margin-left: 24px;

      .activityChip {
        height: 40px;
        background: var(--whoop-color-white);
        border: 1px solid var(--whoop-color-black-alpha-300);
        border-radius: 8px;
        padding: 0px 16px;
        font-family: ProximaNova;
        letter-spacing: 0.5px;
        font-size: 16px;
        line-height: 20px;
        text-transform: none;
        color: var(--whoop-color-black-alpha-700);
        text-transform: capitalize;
      }

      .selectedChip {
        border: 2px solid var(--whoop-color-black-alpha-900);
        color: var(--whoop-color-black-alpha-900);
      }
    }
  }
}

.showBackground {
  background: var(--whoop-color-gray-200);
}

.loadingAnimation {
  animation: loadingColor 1s infinite alternate;
  background-repeat: no-repeat;
}

@keyframes loadingColor {
  from {
    background-color: var(--whoop-color-gray-200);
  }
  to {
    background-color: var(--whoop-color-gray-300);
  }
}
