@import "styles/color.scss";

.container {
    display: flex;
    text-transform: capitalize;
    margin-top: var(--spacing-lg);
    flex-direction: column;
    gap: var(--spacing-xs-5);
    padding-left: 8px;
}

.updateDescription {
    display: inline-flex;
    align-items: c;
    cursor: pointer;
    text-transform: none;

    .emptyDescription {
        font-style: italic;
        color: rgba(0, 0, 0, 0.7);
    }

    input {
        width: 250px;
        font-size: 18px;
        height: 28px;
        border-right: hidden;
        border-top: hidden;
        border-left: hidden;
        border-width: 1px;
    }

    .editIcon {
        color: rgba(0, 0, 0, 0.7);
        padding: 8px;
        height: 20px;
        width: 20px;
        cursor: pointer;
    }

    .editIconClick {
        color: rgba(0, 0, 0, 0.7);
        height: 20px;
        width: 20px;
        margin-left: 8px;
        cursor: pointer;
    }

    .editIcon:hover {
        color: var(--whoop-color-black)
    }
}

.moreActions {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-bottom: 28px;
    margin-top: 16px;
    display: flex;


    .dropdownButton {
        color: rgba(0, 0, 0, 0.7);
        padding: var(--spacing-xs-2) var(--spacing-xs-2);
        margin-top: 4px;
        margin-left: 8px;
    }

    .invitedCollaboratorsContainer {
        display: flex;
        justify-content: flex-start;

        p {
            font-family: ProximaNova;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: rgba(0, 0, 0, 0.7);
        }

        svg {
            width: 16px;
            height: 16px;
            margin-top: 10px;
            margin-right: 12px;
        }

        .concealedSwitch {
          margin-left: 12px;
          margin-top: 8px;
        }
    }

    .concealedIcon {
      margin-left: 28px;
    }

    .menuList {
        li {
            font-family: var(--whoop-font-family-normal);
        }

        .removeMember {
            color: var(--whoop-color-red);
        }
    }
}

.deleteGroup {
    color: var(--whoop-color-red);
}

.groupRow {
  display: flex;
  flex-direction: row;
}

.deleteIconContainer {
  background-color: var(--whoop-color-gray-200);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 1px;

  .deleteIcon {
    width: 20px;
    height: 20px;
  }
}
