.update {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-transform: none;

  .emptyDescription {
    font-style: italic;
    color: var(--color-black-alpha-700);
  }

  .descriptionInput {
    width: 250px;
    font-size: 18px;
    height: 28px;
    border-right: hidden;
    border-top: hidden;
    border-left: hidden;
    border-width: 1px;
  }

  .nameInput {
    width: fit-content;
    font-size: 32px;
    height: 35px;
    border-right: hidden;
    border-top: hidden;
    border-left: hidden;
    border-width: 1px;
  }

  .editIcon {
    color: var(--color-black-alpha-700);
    padding: 8px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .editIconClick {
    color: var(--color-black-alpha-700);
    height: 20px;
    width: 20px;
    margin-left: 8px;
    cursor: pointer;
  }

  .editIcon:hover {
    color: var(--whoop-color-black);
  }
}

.edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  .editIconClick {
    display: none;
    color: var(--color-black-alpha-700);
    height: 20px;
    width: 20px;
    margin-left: 8px;
    cursor: pointer;
  }
}

.edit:hover .editIconClick {
  display: block;
}
