@import '@whoop/web-components/dist/typography.mixin';

.actionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 36px;
  width: 36px;
  padding: 8px;

  background: var(--whoop-color-white-alpha-100);

  border-radius: 8px;
  border: none;
  overflow: visible;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--whoop-color-white-alpha-900);
    color: var(--whoop-color-white-alpha-900);
  }
}

.closeButton {
  height: 36px;
  width: 36px;
  padding: 0;
  border: none;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--whoop-color-white-alpha-900);
    color: var(--whoop-color-white-alpha-900);
  }
}

.csvExportModal {
  width: 612px;
  border-radius: 12px;
  background: var(--whoop-color-background-blue);
  color: var(--whoop-color-white-alpha-900);

  .container {
    padding: 16px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      margin-bottom: 16px;
    }
    
    .link {
      color: var(--whoop-color-white-alpha-900);
    }

    .footer {
      display: flex;
      margin-top: 28px;
      justify-content: flex-end;
      
      .exportButton {
        @include b1;
        font-weight: 700;
        letter-spacing: 1.422px;
      }
    }

  }
}