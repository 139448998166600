@import '@whoop/web-components/dist/typography.mixin';
@import '@whoop/web-components/dist/responsive.mixin';

.datePickerContainer {
  padding: 0;
  margin: 0;
  border: none;
  gap: 16px;
  max-width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  
  @include less-than-lg {
    justify-content: start;
    padding-top: 20px;
  }

  .toggleContainer {
    height: 36px;
  }

  .datePickerButton {
    @include p5;
    border: none;
    height: 36px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: var(--whoop-color-white-alpha-900);

    &:hover {
      background-color: var(--whoop-color-white-alpha-200);
    }
  }

  .datePickerBox {
    top: 0px;
    margin-top: 50px;
    right: 0px;
  }
}
