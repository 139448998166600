.profileContent {
    width: 700px;
    max-width: 1550px;
    overflow: hidden;
}
.section {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    
    h4 {
        color: #969696;
        width: 100%
    }
      
    // This :after style is used to display the straight gray line that goes to the right of the header for each table.
    h4:after {
      content: "";
      display: inline-block;
      height: 2px;
      vertical-align: middle;
      width: 100%;
      margin-right: -100%;
      margin-left: 10px;
      border-top: 2px solid rgba(0, 0, 0, 0.3);
    }
}

.editProfileSection {
    h4 {
        color: #000000
    }
}

.sectionContainer {
    width: 50%
}

.avatar {
    width: 64px;
    height: 64px;
    margin-right: 32px;
}

.detailHeader {
    display: flex;
    align-items: center;
}

.idText {
    font-weight: 400;
    font-size: 32px;
    line-height: 35px;
    color: #212121;
}

.idDetailText {
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #969696;
    margin-top: 8px;
}

.editProfileButtons {
    margin-left: auto;
    display: flex;
}

.moreButton {
    margin-left: 12px;
}

.boldButton {
    font-weight: bold;
    border-width: thin;
}

.inputGroup {
    width: 85%
}

.lastSection {
    margin-bottom: 100px;
    word-wrap: break-word;
}

.headerDescription {
    margin-top: 28px;
}

.weightInput {
    padding-top: 0.25px;
}

.dropdownWidth {
    width: 109%
}

.sensorValue {
    margin-top: 42px;
}

.greyNumbers { 
    color: var(--whoop-color-gray-500);
    margin-right: 12px;
}

