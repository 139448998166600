@import '@whoop/web-components/dist/typography.mixin';

.groupDetailsViewContainer {
  color: var(--text-color);
}

.gridLayout {
  display: grid;
  row-gap: 28px;
  grid-gap: 28px;
  grid-template-columns: repeat(auto-fit, minmax(376px, 1fr));

  color: var(--text-color);
  justify-items: center;
  justify-content: center;
  margin: auto;
  // this helps constrain the cards on the center for big screens
  // somewhat of a temporary solution until we have a better approach to this type of layout
  // 3 * widgetWidth + 2 * gutterWidth
  max-width: calc((3 * 376px) + (2 * 28px));
}

.expanded {
  grid-column: 1/-1;
}

.widgetGridItem {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.widgetGridItem:not(.expanded) {
  min-width: 376px;
}

.hideGroupWrapper {
  color: var(--whoop-color-white);
  border-radius: 4px;
  background: var(--whoop-color-background-blue);
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    height: 28px;
    width: 28px;
    padding-bottom: 16px;
  }

  .hideGroupText {
    @include p5;
  }
}
