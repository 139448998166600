@import "styles/color.scss";
@import "styles/layout";

.table {
  margin-top: 32px;
}

.headerGroup {
  height: $SPACING-6;
}

.stickyHeader {
  overflow: scroll;
  background-color: $WHITE;
  position: sticky;
  z-index: 1;
  width: fit-content;
  top: 64px;
}
