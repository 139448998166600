@import '@whoop/web-components/dist/typography.mixin';
@import 'insights/commonStyles.module';

.leaderboardTable {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  table-layout: fixed;

  td:not(:first-child), th:not(:first-child) {
    width: 94px;
    padding-right: 8px;
  }

  .tableHeaderRow {
    height: 28px;
    position: sticky;
    background-color: var(--color-background-blue);
    top: 0;
  }

  .tableHeader {
    text-align: right;

    &:first-of-type {
      text-align: left;
      // Prevent sorting for Name column
      pointer-events: none;
    }
  }

  .tableHeaderText {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include c2;
    font-weight: 700;
    color: var(--whoop-color-white-alpha-900);
    user-select: none;

    svg {
      height: 8px;
      width: 8px;
      margin-left: 2px;
    }
  }

  .tableHead, .tableBody, .tableRow {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .tableBody {
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
    width: 100%;

    @include insightsScrollbar;
  }

  .leftAlign {
    justify-content: flex-start;
  }

  .tableRow {
    display: table;
    width: 100%;
    height: 36px;

    &.lastRowPadding {
      padding-bottom: 16px;
    }

    &.clickRow {
      cursor: pointer;
    }
  }

  .widgetCell {
    text-align: right;
    padding-right: 8px;
  }

  .bucketHeader {
    @include c2;
    font-weight: 500;
    color: var(--color-white-alpha-500);
    margin-top: 8px;
    margin-bottom: 4px;
    display: table;
    width: 100%;
    position: sticky;
    background-color: var(--color-background-blue);
    top: 0;
    padding-right: 8px;

    .bucketHeaderCell {
      display: flex;
      align-items: center;

      .bucketHeaderLine {
        flex-grow: 1;
        height: 50%;
        margin-left: 8px;
        border-bottom: 0.0625rem solid var(--color-white-alpha-50);
      }
    }
  }
}

.gradient {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 22px;
  width: inherit;
  background: linear-gradient(180deg, rgb(0, 0, 0, 0) 0%, var(--color-background-blue) 100%);
  transition: opacity .2s ease-in-out;
  pointer-events: none;
  opacity: 1;
  padding: 0;

  &.hide {
    opacity: 0;
  }
}
