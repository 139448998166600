.formFields {
  margin-top: 40px;
}

.errorText {
  padding: 4px 12px 4px 12px;
  color: #f44336;
  font-size: 12px;
}

.title {
  margin-bottom: 24px;
}

.overTwo {
  color: #C8C8C8;
  margin-right: 4px;
}