.shippingTypeHeader {
  margin: 44px 0 12px;
  height: 14px;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #969696;
}

.bulkAlert {
  margin-top: 16px;
}

.bulkAlertText {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}