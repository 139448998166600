@import "styles/color";

.checkbox {
  position: absolute;
  opacity: 0;
  height: 0px;
  width: 0px;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkmarkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  border-radius: 2px;

  .checkIcon {
    opacity: 0;
  }
  .indeterminate {
    opacity: 0;
  }
}

.checkbox:hover ~ .checkmark {
  display: flex;
  align-items: center;
  justify-content: center;

  .checkIcon {
    opacity: 1 !important;
  }
  .indeterminate {
    opacity: 1 !important;
    position: absolute;
    z-index: -1;
  }

  .path {
    fill: $GRAY_700;
  }

  &[aria-disabled="true"] {
    cursor: default;
    .path {
      fill: #f9f9f9;
    }
  }
}

.checkbox:checked ~ .checkmark {
  background-color: $STRAIN_BLUE;
  border: 1px solid $STRAIN_BLUE;
  display: flex;
  align-items: center;
  justify-content: center;

  .checkIcon {
    opacity: 1 !important;
  }
  .indeterminate {
    display: none;
  }

  .path {
    fill: $WHITE;
  }
}

.checkbox:indeterminate ~ .checkmark {
  background-color: $STRAIN_BLUE;
  border: 1px solid $STRAIN_BLUE;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .checkIcon {
    display: none;
  }
  .indeterminate {
    opacity: 1 !important;
  }
}

.checkmark[aria-disabled="true"] {
  border: 1px solid #c0c0c0;
  background: #f9f9f9;
  pointer-events: all;

  &:hover {
    .path {
      fill: #f9f9f9;
    }
  }
}

.tooltip {
  background: rgba(26, 34, 39, 0.9) !important;
  border-radius: 6px;
  font-family: ProximaNova !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF; 
}
