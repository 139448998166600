@import "styles/theming.scss";
@import '@whoop/web-components/dist/typography.mixin';


// Custom header on month
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 36px;

  .monthName {
    @include p5;
    padding-left: 12px;
    font-weight: 600;
    color: var(--date-picker-text-color);
  }

  .prevMonthButton {
    margin-right: 8px;
  }

  .prevMonthButton,
  .nextMonthButton {
    height: 36px;
    width: 36px;
    background-color: var(--date-picker-button-background-navigation);
    border-radius: 8px;

    color: inherit;
    border: none;
    padding: 8px;
    cursor: pointer;
    outline: inherit;

    .caretIcon {
      height: 20px;
      width: 20px;
      color: var(--date-picker-text-color);
    }

    &:hover {
      background-color: var(--date-picker-button-background-hover-navigation);
    }
  }

  .prevMonthButton:disabled,
  .nextMonthButton:disabled {
    cursor: default;
    background-color: var(--date-picker-disabled-button-background-navigation);

    .caretIcon {
      height: 20px;
      width: 20px;
      color: var(--date-picker-disabled-caret-icon);
    }
  }
}