.container {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  padding-top: 100px;

  .content {
    max-width: 100%;
    width: 688px;
  }

  h3 {
    margin-bottom: 32px;
  }
}
