@import "styles/color.scss";

.bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;
}

.returnToSignIn {
  font-size: var(--whoop-font-size-m);
  text-transform: capitalize;
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
  letter-spacing: 1px;
}

.submitted {
  margin-top: 36px;
}

.infoText {
  width: 336px;

  h1 {
    color: $WHITE;
    font-size: 28px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: var(--spacing-sm-2);
    font-family: ProximaNova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #969696;
  }
}

.formContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 336px;

  h4 {
    color: white;
  }

  .signInButton {
    margin-top: 48px;
    width: 336px;
  }

  .inputField {
    border: 2px solid transparent;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -1px 0px #ffffff;
    height: 36px;
    width: auto;
    outline: none;
    padding: 0 var(--spacing-xs-3) 0 var(--spacing-xs-3);
    margin-bottom: 4px;

    &:focus-visible {
      border: 2px solid $STRAIN_BLUE;
      outline: none;
    }
  }

  .inputFieldInvalid {
    background: rgba(255, 0, 38, 0.2);
    box-shadow: inset 0px -1px 0px #ff0026;
  }

  .invalidMessage {
    border-left: 2px solid transparent;
    color: $BRANDING_RED;
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
    height: 16px;
    margin-bottom: 12px;
  }

  input {
    color: $WHITE;
    font-family: var(--whoop-font-family-bold);
    font-size: 14px;
  }

  .inputFieldLabel {
    font-family: var(--whoop-font-family-bold);
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    margin: 0 0 6px 0;
    width: auto;
    text-align: left;

    color: rgba(255, 255, 255, 0.5);
  }

  button {
    margin-top: 15px;
  }
}
