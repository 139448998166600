.optionSelectButton {
  height: 92px;
  width: 336px;
  border: none;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background: #F5F5F5;

  .header {
    font-family: ProximaNova;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
  }
  
  .description {
    font-family: ProximaNova;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    text-align: center;
    opacity: 0.7;
  }

  &.active {
    background: black;

    .header {
      color: #FFFFFF;
    }

    .description {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  &.disabled {
    cursor: not-allowed;
    .header {
      color: rgba(0, 0, 0, 0.5);
    }

    .description {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}