.barLabel {
  font-family: 'DINPro';
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
}

.pillarBreakdownBar:hover {
  cursor: pointer;
}

.dailyGraphBar {
  border-radius: 2px 2px 0 0;
}

.insightsBarLabelText {
  font-size: 13px;
}
