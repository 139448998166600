@import "styles/color";

.badgeContainer {
  display: inline-block;
  border-radius: 2px;
  padding: 4px;

  width: max-content;
  display: flex;
}

.primary {
  background: rgba(#19ec06, 0.2);
}
.secondary {
  background: $GRAY_300;
}
.lightBlue {
  background: var(--color-strain-blue-alpha-200);
}
.danger {
  background: rgba(#d7001e, 0.3);
}
.warning {
  background: rgba(#ffde00, 0.2);
}
.offlineWarning {
  background: rgba(255, 167, 34, 0.15);
}

.none {
  background: none;
}

.notificationRed {
  background: rgba(255, 179, 179, 0.7);
}