@import '@whoop/web-components/dist/typography.mixin';

.datePickerButton {
  background: var(--date-picker-button-background);
  border: var(--whoop-color-gray-600) 1px solid;
  min-width: 190px;
  height: 44px;
  cursor: pointer;
  padding: 12px 16px;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 190px;
  border-radius: 8px;
  text-align: left;
  color: var(--date-picker-text);

  span {
    width: 100%;
  }
  .calendarIcon {
    height: 20px;
    width: 20px
  }
}

.datePickerBox {
  z-index: 1000;
  position: absolute;
  right: 88px;
  background: var(--date-picker-background);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  color: var(--date-picker-text);
  border-radius: 8px;
  margin-top: 50px;
}

.dayButton {
  &:active, &:focus {
    border-color: #0093E7 !important;
  }
}

.dayButton:hover:not([disabled]) { 
  background-color: var(--date-picker-day-hover) !important;
}

.daySelected {
  color: var(--whoop-color-white);
  background-color: var(--whoop-color-strain-blue);
}

.dayRangeStart {
  color: white !important;
  background-color: #0093E7 !important;
  border: none !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  &.dayRangeEnd {
    border-radius: 100%;
  }

  &.daySelected {
    color: white;
  }
}

.dayRangeEnd {
  color: white !important;
  background-color: #0093E7 !important;
  border: none !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayRangeMiddle {
  color: var(--date-picker-text) !important;
  background: rgba(0, 147, 231, 0.1) !important;
  border-radius: 0;
}

.rangeText {
  @include c1;
  margin-right: 16px;
}

.numDays {
  color: var(--date-picker-grey-text);
  @include c1;
}

.footer {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: var(--date-picker-border-line) 1px solid;
}


.actions {
  display: flex;
  gap: 16px;

  .button {
    border-radius: 4px;
    padding: 12px 16px;
    width: fit-content;
    height: 40px;
    margin: 0 8px;
    @include b1;
    letter-spacing: 0.1em;
  
    &.apply {
      color: var(--whoop-color-white);
      background: var(--whoop-color-strain-blue);
      border: 1px solid #089BE4;
      border-radius: 44px;
      width: 87px;
    }
    &.cancel {
      color: var(--whoop-color-strain-blue);;
      background: none;
    }
  }
  
  .button:disabled,
  .button[disabled] {
    opacity: 0.3;
  }
}

.rowBody {
  border-bottom: inherit 4px solid; // White space between rows
}

.rowHead {
  border-bottom: none;
  color: var(--date-picker-grey-text);
}

.monthMover:hover:not([disabled]) { 
  background-color: var(--date-picker-day-hover) !important;
}

.monthCaption {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  text-align: left;
  color: var(--date-picker-text);
}

.captionStart {
  .nav {
    position: absolute;
    top: 50%;
    left: 510px;
    transform: translateY(-50%);
    z-index: 10;
  }
}

.captionEnd {
  .nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
