@keyframes heartbeat {
  0% {
    transform: scale(0.8);
  }
  8% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  28% {
    transform: scale(0.75);
  }
  36% {
    transform: scale(0.92);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.8);
  }
}

.pulsingLogo:before {
  transform: scale(0.75);
  transition: opacity 0.4s ease-in-out;
  animation: heartbeat 1.1s ease infinite;
  background-image: url("../../assets/spinner.svg");
  background-size: contain;
  content: "";
  display: block;
  height: 12rem;
  left: 50%;
  margin: -6rem 0 0 -6rem;
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 12rem;
  z-index: 951;
}

.overlayLogo:before {
  color: #000;
  background-image: url("../../assets/spinner-logo.svg");
}
