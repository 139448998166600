@import '@whoop/web-components/dist/typography.mixin';

.trendBox {
  @include n5;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  align-items: center;
  margin-left: 8px;
  gap: 4px;
  border-radius: 4px;

  .trendIcon {
    width: 8px;
    height: 8px;
  }
}