@import '@whoop/web-components/dist/typography.mixin';

.groupsDropdown {
  display: flex;
  margin-left: auto;
  margin-right: 32px;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;

  .dropdownButton {
    @include p5;
    color: var(--text-color);
    display: flex;
    align-items: center;
  }

  .dropdownIcon {
    width: 10px;
    margin-left: 16px;
    margin-bottom: 2px;
  }

  .dropdownItemLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #444444;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .activeItem {
    .dropdownItemLabel {
        font-family: var(--whoop-font-family-bold);
        margin-left: 0px;
    }
  }
  .inactiveItem {
    .dropdownItemLabel {
        margin-left: 25px;
    }
  }
}

.activeIcon {
  width: 12px;
  height: 10px;
  margin-right: 12px;
}

.disableClick {
  pointer-events: none;
}
