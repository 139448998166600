//Colors come from storybook

//Tones
$BLACK: var(--whoop-color-black);
$WHITE: var(--whoop-color-white);

//Hues
$BRANDING_RED: var(--whoop-color-branding-red);
$RED: var(--whoop-color-red);
$YELLOW: var(--whoop-color-yellow);
$NOTES_YELLOW: var(--whoop-color-notes-yellow);
$GREEN: var(--whoop-color-green);
$TEAL: var(--whoop-color-teal);
$STRAIN_BLUE: var(--whoop-color-strain-blue);
$SLEEP_BLUE: var(--whoop-color-sleep-blue);
$RECOVERY_BLUE: var(--whoop-color-recovery-blue);
$DARY_GRAY: var(--whoop-color-dark-gray);
$BACKGROUND_BLUE: var(--whoop-color-background-blue);
$BACKGROUND_DARKBLUE: var(--whoop-color-background-darkblue);

// Red Tones
$RED_ALPHA_300: var(--whoop-color-red-alpha-300);
$RED_ALPHA_200: var(--whoop-color-red-alpha-200);

//Gray Tones
$TEXT_OVERRIDES_1: #212121;
$TEXT_OVERRIDES_2: #757575;

$GRAY_700: var(--whoop-color-gray-700);
$GRAY_600: var(--whoop-color-gray-600);
$GRAY_500: var(--whoop-color-gray-500);
$GRAY_400: var(--whoop-color-gray-400);
$GRAY_300: var(--whoop-color-gray-300);
$GRAY_200: var(--whoop-color-gray-200);

//Black Transparent Tones
$BLACK_ALPHA_900: var(--whoop-color-black-alpha-900);
$BLACK_ALPHA_700: var(--whoop-color-black-alpha-700);
$BLACK_ALPHA_500: var(--whoop-color-black-alpha-500);
$BLACK_ALPHA_300: var(--whoop-color-black-alpha-300);
$BLACK_ALPHA_200: var(--whoop-color-black-alpha-200);
$BLACK_ALPHA_100: var(--whoop-color-black-alpha-100);
$BLACK_ALPHA_50: var(--whoop-color-black-alpha-50);

//White Transparent Tones
$WHITE_ALPHA_900: var(--whoop-color-white-alpha-900);
$WHITE_ALPHA_700: var(--whoop-color-white-alpha-700);
$WHITE_ALPHA_500: var(--whoop-color-white-alpha-500);
$WHITE_ALPHA_300: var(--whoop-color-white-alpha-300);
$WHITE_ALPHA_200: var(--whoop-color-white-alpha-200);
$WHITE_ALPHA_100: var(--whoop-color-white-alpha-100);
$WHITE_ALPHA_50: var(--whoop-color-white-alpha-50);
