@import '@whoop/web-components/dist/typography.mixin';

.cellText {
  @include n5;
  font-weight: 700;
  line-height: 20px;
}

.numberCell {
  color: var(--whoop-color-recovery-blue);
}

.minutesToHoursCell {
  color: var(--whoop-color-sleep-blue);
}

.strainCell {
  color: var(--whoop-color-strain-blue);
}

.emptyCell {
  color: var(--whoop-color-white-alpha-500);
}