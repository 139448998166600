.pillarBarCard {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  .pillarBreakdownBar {
    margin-top: 24px;
    height: 32px;
    width: 100%;

  }
  
  .overviewPageTopMargin {
    margin-top: 0px;
  }
  .labels {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: auto;
  }
}