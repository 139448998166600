@import "styles/color.scss";
@import "styles/layout.scss";

.navContainer {
  display: flex;
  max-width: 100vw;
  background-color: #0d1113;
  height: 78px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);

  button {
    color: $WHITE;
  }

  .left {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-left: 32px;
  }

  .right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 8px;
  }
}

.headerLogo {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  user-select: none;

  .whoopLogo {
    width: 200px;
  }
}

.tabs {
  margin-left: 24px;
  display: flex;
  height: 100%;
}
