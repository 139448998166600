.input {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #f5f5f5;
  border-radius: 4px;
  width: 100%;
  height: 44px;
  padding: 0 12px 0 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 16px 8px 0;
  font-size: 14px;

  font-family: ProximaNova;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.input:focus {
  outline: none;
}

.inputLabel {
  display: block;
  transform-origin: top left;
  color: #969696;
  font-size: 11px;
  margin-top: 20px;
  font-family: ProximaNova;
  font-weight: 900;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.charLimit {
  display: flex;
  justify-content: flex-end;
  font-family: var(--whoop-font-family-normal);
  color: rgba(0, 0, 0, 0.5);
  font-size: var(--font-size-s);
}

.textArea {
  padding: var(--spacing-xs-3);
  resize: none;
}

.dropdown {
  display: flex;
  font-family: var();
  padding: var(--spacing-xs-3);
  height: 46px;
  width: 100%;
  font-family: var(--whoop-font-family-normal);
  color: rgba(0, 0, 0, 0.5);
}

.disabled {
  opacity: 0.5
}

// This block of styling is customizing the dropdown arrow and padding
select {
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

select.dropdown {
  background-image:
    linear-gradient(45deg, transparent 50%, #757575 50%),
    linear-gradient(135deg, #757575 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position:
    calc(100% - 20px) calc(1em + 4px),
    calc(100% - 16px) calc(1em + 4px),
    100% 0;
  background-size:
    8px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

.dropdown::after {
  right: 10px;
  position: absolute;
  content: "▼";
  font-size: 1rem;
}