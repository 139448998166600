@import 'styles/theming.scss';
@import '@whoop/web-components/dist/typography.mixin';

.datePickerContainer {
  width: fit-content;
  position: relative;

  .modalBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--overlay-background);
    z-index: 10;
  }

  .buttonGroup {
    display: flex;
    flex-direction: row;
    background-color: var(--date-picker-background-color);
    border-radius: 8px;
    position: relative;
    z-index: 50;

    :first-child:after {
      content: '';
      position: relative;
      height: 21px;
      margin: auto;
      border-right: 1px solid transparent;
    }

    &.noSelection {
      :first-child:after {
        content: '';
        position: relative;
        height: 21px;
        margin: auto;
        border-right: 1px solid var(--date-picker-background-color);
      }
    }

    .dateButton {
      @include p5;
      position: relative;
      display: flex;
      justify-content: center;
      align-content: center;
      color: var(--date-picker-text-color);
      border-radius: 2px;
      white-space: nowrap;

      &:hover {
        background-color: var(--date-picker-input-hover-color);
        border-radius: 8px;
        cursor: pointer;
      }

      input[type='radio'] {
        display: none;
        width: 100%;
      }

      .selected {
        border-radius: 8px;
        box-sizing: content-box;
        border: 1px solid var(--date-picker-input-selected-border);
      }

      span {
        width: 92px;
        padding: 8px 8px 8px 16px;
        border: 1px solid transparent;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
      }
    }

    .calendarIcon {
      color: var(--date-picker-text-color);
      height: 20px;
      width: 20px;
      padding: 8px;
    }
  }

  .modalContainer {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0px;
    border-radius: 8px;
    margin-top: 8px;
    background: var(--date-picker-modal-background-color);
    justify-content: flex-end;
    width: 732px;
    box-shadow: 0px 0px 8px 0px var(--color-black-alpha-300);
    z-index: 50;

    .presetContainer {
      width: 140px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-right: 1px solid var(--date-picker-background-color);

      .quickSelectLabel {
        @include c1;
        color: var(--date-picker-secondary-text-color);
        font-weight: 500;
        line-height: 16px;
        padding-left: 12px;
        padding-top: 14px;
        padding-bottom: 12px;
      }

      .quickSelectButtons {
        padding-left: 12px;

        .quickSelectButton {
          border: none;
          padding: 10px 16px;
          @include c2;
          font-weight: 500;
          margin-bottom: 8px;
          color: var(--quick-select-text-color);
          border-radius: 16px;
          background: var(--quick-select-background-color);

          &:hover:not(.selectedButton) {
            cursor: pointer;
            color: var(--quick-select-hover-text-color);
            background: var(--quick-select-hover-background-color);
          }
        }

        .selectedButton {
          background: var(--quick-select-selected-background-color);
          color: var(--quick-select-selected-text-color);
          &:hover {
            cursor: pointer;
            color: var(--quick-select-selected-hover-text-color);
            background: var(--quick-select-selected-hover-background-color);
          }
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid var(--date-picker-background-color);
      padding: 4px 8px 4px 16px;

      .dateRangeString {
        @include c1;
        font-weight: 500;
        color: var(--date-picker-text-color);

        .numOfDays {
          padding-left: 16px;
          color: var(--date-picker-secondary-text-color);
        }
      }

      .actionButton {
        @include b1;
        padding: 12px 20px;
        color: var(--date-picker-selected-date-dot-color);
        letter-spacing: 0.08888rem;
      }
    }
  }
}

// styles calendar
.rootCalendar {
  margin: 0;
  width: 592px;
}

.table {
  margin-top: 8px;
  border-spacing: 0 4px;
}

.month {
  padding: 4px;

  &:first-of-type {
    margin-right: 16px;
  }
}

.rowHead {
  @include c2;
  color: var(--date-picker-secondary-text-color);
  height: 16px;

  & > .head_cell {
    height: 16px;
  }
}

.day {
  @include p5;
  color: var(--date-picker-text-color);
  height: 40px;
  width: 40px;
  opacity: 1;
  padding: 0;
  border: none;
  cursor: inherit;
  outline: inherit;
  z-index: 0;

  // blue dot on end ranges of selected date range
  &.day_range_end,
  &.day_range_start {
    color: var(--date-picker-selected-text-color);

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: 100%;
      background-color: var(--date-picker-selected-date-dot-color);
      cursor: pointer;
      z-index: -1;
    }

    &:hover {
      &:before {
        background-color: var(--date-picker-selected-day-hover);
      }
    }
  }

  // selected day background color
  &.day_range_middle {
    cursor: pointer;
    background-color: var(--date-picker-selected-date-background-color);
  }
  &.day_range_start:not(.day_range_end) {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: var(--date-picker-selected-date-background-color);
  }
  &.day_range_end:not(.day_range_start) {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: var(--date-picker-selected-date-background-color);
  }

  &.today_date {
    // today date dot
    &:after {
      content: '';
      position: absolute;
      border-radius: 100%;
      transform: translateX(-50%);
      background: var(--date-picker-today-dot-color);
      height: 2px;
      width: 2px;
      left: 50%;
      bottom: 4px;
    }
  }

  // hover styles
  &:hover:not([disabled]):not(.day_range_end):not(.day_range_start) {
    color: var(--date-picker-date-hover-color);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 100%;
      background-color: var(--date-picker-date-hover-background-color);
      border: 1px solid var(--date-picker-date-hover-border-color);
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}

.disabled_day {
  @include p5;
  font-weight: 400;
  color: var(--date-picker-disabed-day);
  opacity: 1;
}

.startGradient {
  height: 40px;
  width: 40px;
  background: linear-gradient(90deg, rgba(0, 147, 231, 0.1) 50%, rgba(0, 147, 231, 0) 100%);
}
.endGradient {
  height: 40px;
  width: 40px;
  background: linear-gradient(270deg, rgba(0, 147, 231, 0.1) 50%, rgba(0, 147, 231, 0) 100%);
}
