.wrapper {
  background-color: var(--whoop-color-white);
  border: 1px solid var(--whoop-color-gray-300);
  border-radius: 8px;
  margin-top: 16px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  .alignLeft,
  .headerContainer {
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .alignLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .radioInput[type='radio'] {
      margin: 10px;
      height: 16px;
      width: 16px;

      appearance: none;
      -webkit-appearance: none;
      /* For a circular appearance we need a border-radius. */
      border-radius: 50%;
      /* The background will be the radio dot's color. */
      background: var(--color-white);
      /* The border will be the spacing between the dot and the outer circle */
      border: 3px solid #fff;
      /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
      box-shadow: 0 0 0 1px var(--color-gray-700);
    }

    .radioInput[type='radio']:checked {
      appearance: none;
      -webkit-appearance: none;
      border-radius: 50%;
      background: var(--color-strain-blue);
      border: 3px solid #fff;
      box-shadow: 0 0 0 1px var(--color-strain-blue);
    }

    .radioInput[type='radio']:not(:checked):hover {
      appearance: none;
      -webkit-appearance: none;
      border-radius: 50%;
      background: var(--color-gray-700);
      border: 3px solid #fff;
      box-shadow: 0 0 0 1px var(--color-gray-700);
    }
  }

  .tableBody {
    border-bottom: 1px solid var(--color-gray-300);
    border-top: 1px solid var(--color-gray-300);
  }
  .activeCell {
    background-color: var(--color-strain-blue-alpha-100);
  }
}

.tableFooter {
  padding-left: 12px;
  padding-top: 16px;
  padding-bottom: 12px;
  display: flex;
  gap: 20px;

  .key {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .icon {
    height: 10px;
    width: 10px;
  }
}
