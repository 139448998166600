@import "styles/color";
@import "styles/layout";

.tableContainer {
  background: var(--whoop-color-white);
  box-sizing: border-box;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;
  width: 100%;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;

  .tooltip {
    position: absolute;
    color: var(--whoop-color-white);
    background: var(--whoop-color-black);
    width: fit-content;
    border-radius: 6px;
    padding: 6px;
    font-family: ProximaNova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    z-index: 1;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    p {
      font-family: var(--whoop-font-family-normal);
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.viewGroupBtn.show {
  visibility: visible;
}

.viewGroupBtn {
  visibility: hidden;
}

.controls {
  display: flex;
  align-items: center;
  width: 100%;

  .left {
    justify-content: flex-start;
    flex-grow: 1;
    display: flex;
  }

  .button {
    padding: 12px 16px;
    border-radius: 4px;
    width: 168px;
    height: 40px;
  }
}

.collaborators {
  display: flex;
  flex-direction: row;
}

.avatar {
  display: inline-block;
  width: var(--spacing-sm-5);
  height: var(--spacing-sm-5);
  margin-right: var(--spacing-xs);
  border-radius: 50%;
  background-color: rgba(196, 196, 196, 0.4);
}

[data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
  padding-top: var(--spacing-sm-5);
}

[data-tooltip]:hover:before {
  font-family: var(--whoop-font-family-normal);
  opacity: 1;
}

.collaboratorAccess {
  width: fit-content;
  padding: var(--spacing-xs);
  background: rgba(0, 0, 0, 0.05);
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  gap: var(--spacing-xs);
}

.icon {
  width: var(--spacing-xs-3);
  height: var(--spacing-xs-4);
}
