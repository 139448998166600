@import '@whoop/web-components/dist/typography.mixin';

.hoverData {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .rangeText {
    @include c2;
    font-weight: 500;
    white-space: nowrap;
    align-items: center;
    display: flex;
  }

  .nameAndAvatar {
    display: flex;
    gap: 8px;

    .name {
      @include c2;
      font-weight: 500;
      margin: auto;
      text-transform: capitalize;
    }

    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin: auto;
    }
  }

  .sizedLabel {
    flex-basis: 36%;
  }

  div.smallGapRangeDataSection {
    gap: 0px;
    justify-content: flex-end;
  }

  .rangeDataSection {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .label {
      display: flex;
      align-items: center;
      text-align: center;

      .icon {
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }

      .labelSquare {
        width: 8px;
        height: 8px;
        margin-right: 8px;
      }

      .labelCount {
        @include n6;
        margin-right: 2px;
      }

      .labelName {
        @include c1;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        color: var(--whoop-color-white-alpha-500);
        white-space: nowrap;
      }
    }
  }

  .dailyDataSection {
    display: flex;
    gap: 20px;

    .trendIndicator {
      display: flex;
      gap: 4px;

      .trendIcon {
        width: 8px;
      }
    }

    .header {
      text-transform: capitalize;
      color: var(--whoop-color-white-alpha-500);
      font-size: 10px;
      font-weight: 600;
      line-height: 13px;
      margin-bottom: 2px;
    }

    .data {
      @include n6;
      font-size: 12.64px;
    }

    .metricValue {
      @include n5;
    }

    .strainText {
      color: var(--whoop-color-strain-blue);
    }

    .trainingZoneLabel {
      display: flex;
      flex-direction: row;
      @include c2;
      text-transform: capitalize;
      font-weight: 500;
      line-height: 16px;
      align-items: center;

      .labelSquare {
        width: 8px;
        height: 8px;
        margin-right: 4px;
        border-radius: 2px;
      }
    }

  }
}


