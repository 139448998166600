.acceptPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.067029);
}

.content {
  background-color: var(--whoop-color-gray-100);
  height: 100%;
}

.acceptMessage {
  margin-left: auto;
  margin-right: auto;
  width: 480px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
