@import "styles/color.scss";

.error {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 336px;
  text-align: center;
  justify-content: space-between;

  .top {
    margin-top: 8vh;
  }

  .bottom {
    margin-bottom: 5vh;
  }

  button {
    margin-top: var(--spacing-xs-5);
    width: 336px;
  }

  h1 {
    color: $WHITE;
    font-size: 28px;
    font-weight: 400;
    font-family: var(--whoop-font-family-normal);
  }

  p {
    font-size: 14px;
    color: #969696;
    font-family: var(--whoop-font-family-bold);
  }

  img {
    margin: 0 auto;
    height: 60px;
    width: 60px;
    margin-bottom: 24px;
  }

  .returnToSignIn {
    font-size: var(--whoop-font-size-m);
    text-transform: capitalize;
    text-decoration: underline;
    color: #ffffff;
    cursor: pointer;
    letter-spacing: 1px;
  }
}
