.tooltipContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  box-sizing: border-box;

  background: var(--whoop-color-gray-100);
  border: 0.5px solid var(--whoop-color-black-alpha-700);
  border-radius: 4px;
  position: absolute;
  width: 88px;
  left: 100;
  margin-left: 20px;
  margin-top: -75px;
  font-family: ProximaNova;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-size: 14.22px;

  .mainText {
    font-weight: 700;
    padding-top: 8px;
    padding-bottom: 8px;
    color: var(--whoop-color-strain-blue);
  }

  .subText {
    color: var(--whoop-color-black-alpha-500);
    font-weight: 500;
    padding-bottom: 8px;
  }
}

.pillarBreakdownBarToolTip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;
  padding-right: 12px;
  height: 44px;
  background: var(--whoop-color-white);
  border-radius: 4px;
  line-height: 20px;
  box-shadow: 0px 0px 4px var(--color-black-alpha-300);
  filter: drop-shadow(0px 0px 10px var(--whoop-color-black-alpha-300));

  .tooltipLine {
    display: flex;
    font-family: var(--whoop-font-family-normal);
    font-weight: 400;
    font-size: 16px;
  }

  .name {
    color: var(--whoop-color-black-alpha-900);
  }

  .description {
    color: var(--whoop-color-black-alpha-500);
  }
}

.tooltipBox:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-top: 5px solid var(--whoop-color-white);
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
}

.strainRecoveryTooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border: 1px solid var(--whoop-color-gray-400);
  width: fit-content;

  .tooltipContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .date {
    border-top: 1px solid var(--whoop-color-gray-400);
    background: var(--whoop-color-gray-200);
    padding: 12px;
  }
}

.strainRecoveryFixedWidth {
  width: 136px;
}

.analyticsTooltipContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  background: var(--whoop-color-gray-100);
  border: 0.5px solid var(--whoop-color-black-alpha-700);
  border-radius: 4px;
  position: absolute;
  font-family: ProximaNova;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;

  &.applyPositionRight {
    right: 5px;
  }

  .date {
    font-size: 12.64px;
    letter-spacing: 0.5px;
    color: var(--whoop-color-black-alpha-500);
  }

  .metricLine {
    display: flex;
    width: 100%;
    font-size: 14.22px;
    letter-spacing: 0.5px;
    color: var(--whoop-color-black-alpha-900);
    justify-content: space-between;

    &.title {
      border-bottom: 1px solid var(--whoop-color-gray-300);
      padding-bottom: 8px;;
    }

    .colorSwatch {
      height: 12px;
      width: 12px;
      border-radius: 2px;
      margin-right: 4px;
    }

    .metricName {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
      white-space: nowrap;
    }

    .metricVal {
      font-weight: 700;
      white-space: nowrap;   
    }
  }
}
