.infoCompSize {
  width: 20px;
  height: 20px;
  color: var( --color-gray-700);
}

.infoTooltip {
  height: 20px;
}

.headerText {
  color: var(--whoop-color-black-alpha-900);
}

.detailsText {
  white-space: pre-line;
  color: var(--whoop-color-gray-700);
}

.tooltipContent {
  width: 247px;
  padding: 6px;
  white-space: pre-line;
  color: var(--whoop-color-gray-700);
  text-align: left;
}
