.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;

    .content {
        width: 50%;
        padding-top: var(--spacing-lg);
        margin: auto;
        padding-bottom: var(--spacing-lg);
    }

    .subtitle {
        padding-bottom: var(--spacing-md);
        font-family: ProximaNova;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.01em;
    }

    .selected {
        font-family: ProximaNova;
        font-style: bold;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: #757575;
    }
}
