.messagePage {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;
  padding-top: 100px;
  align-items: center;
  color: var(--color-white);  
  width: 650px;
  gap: 24px;
  
  h2 {
    color: var(--color-white);  
  }
  
  .subtitle {
    color: var(--color-white);  
  }

  .icon {
    width: 60px;
    height: 60px;
  }

  .errorIcon {
    height: 60px;
    width: 60px;
    margin-bottom: 25px;
  }
}
