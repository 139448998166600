@import '@whoop/web-components/dist/typography.mixin';

.jobTitleRow {
  display: flex;
  height: 44px;
  gap: 16px;

  .dropdown {
    background-color: var(--whoop-color-white);
  }

  .numUsersSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .number {
      @include n5;
      color: var(--whoop-color-black-alpha-900);
    }
  
    .userIconSize {
      height: 16px;
      width: 16px;
    }
  }

  .iconContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    visibility: hidden;
    cursor: pointer;

    .iconSize {
      height: 24px;
      width: 24px;
    }
  }

  .showIcon {
    visibility: visible;
  }
}