@import "styles/color.scss";

.footerContainer {
  background: $BLACK;
  border: 1px solid $BLACK;
  box-sizing: border-box;

  min-height: 95px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  position: absolute;
  bottom: 0;
}

.relativeContainer {
  position: relative;
}

.logo {
  width: 200px;
  margin-left: 30px;
}

.footerLinks {
  color: $WHITE;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  p {
    margin-right: 12px;
  }
}

div.footerLinks .boldLink {
  font-weight: 700;
  cursor: default;
}

div.footerLinks > span {
  margin: 15px 20px 15px 0px;
}

div.footerLinks > span:last-child {
  margin-right: 30px;
}

.actionContainer {
  margin-left: 20px;

  button {
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
  }
}

.details {
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  padding-right: 12px;
  padding-left: 12px;
}