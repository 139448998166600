@import "styles/color.scss";

.stickyFooter {
  position: absolute;
  bottom: 92px;
  width: 100%;

  .footerContent {
    padding: 12px 40px;
    border-top: 2px solid var(--whoop-color-gray-400);
    background-color: $WHITE;
  }

  button {
    margin: 0 10px;
  }
}
