@import "styles/color";
@import "styles/layout";

.viewAccountBtn {
  visibility: hidden;
  display: flex;
  align-items: center;
  width: 180px;
  height: 88px;
  margin-right: 8px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 4px;
    :hover {
      background-color: none;
    }
  }
  svg {
    margin-left: 8px;
    fill: var(--whoop-color-strain-blue);
  }
}

.viewAccountBtn.show {
  visibility: visible;
}

.tooltip {
  span {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    margin: 0 auto;
    padding: 12px 8px;
    min-width: 472px;
    transform: translate(-70%, 55%);
    border-radius: 5px;
    background-color: var(--whoop-color-background-darkblue);
    text-transform: none;
    color: $WHITE;
    text-align: center;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    span {
      opacity: 1;
      visibility: visible;
      z-index: 1;
      transition: all 0.2s ease-in-out;
    }
  }
}
