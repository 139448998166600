@import '@whoop/web-components/dist/typography.mixin';
@import 'insights/commonStyles.module';

.container {
    display: flex;
    flex-direction: column;
}

.row {
    padding: 1rem 0;
    display: flex;
    height: 36px;
    gap: 16px;
    z-index: 100;

    & > :first-child {
        max-width: 300px;
        min-width: 200px;
        flex-grow: 1;
    }

    & > :last-child {
        margin-left: auto;
    }
}

.paginationRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;

  .rowsPerPage {
    @include p5;
    color: var(--whoop-color-white-alpha-900);
    margin-right: 8px;
  }

  .updatePaginationDropdown {
    :first-child {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.dateSelector {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.radioToggle {
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    align-items: center;
    padding: 8px 12px;

    input[type="radio"]:checked + label {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        margin: 0;
        border: none;
    }
}

.stickyRow {
  position: sticky;
  top: 0;
  background-color: var(--background-color);
}

.headerStickyRow {
  top: 68px;
  box-shadow: inset 0 -1px 0 var(--whoop-color-white-alpha-500);
}

.breakdownTable {
    border-collapse: collapse;

    th {
        font-family: 'ProximaNova-Bold';
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: .1em;
        width: 20%;
    }

    td {
        font-family: 'DinPro';
        font-size: 15px;
    }

    th, td {
      padding: 1.25rem 0 1.25rem 1.25rem;
      text-align: left;
      user-select: none;
    }

    .tableRow {
      border-bottom: 1px solid var(--whoop-color-white-alpha-100);
    }

    tr {
        overflow: hidden;
        color: var(--text-color);
        border-left: 1rem;

        &.loadingRow {
            border: none;

            td {
                height: 4px;
                padding: 0;
            }
        }

        &.selectable {
            cursor: pointer;

            &:hover {
                background-color: var(--whoop-color-white-alpha-50)
            }
        }
    }

    .header {
        display: flex;
        align-items: center;

        min-height: 1.25rem;
    }
}

.summaryRow {
  border-bottom: 1px solid var(--whoop-color-white-alpha-500);
}

.noData {
    align-self: center;
    padding: 1.25rem;
    color: var(--text-color);
}

.filterDropdown {
  flex: 0 0 140px;
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.capitalize p {
    text-transform: capitalize;
  }
}

.rowsPerPageDropdown {
  height: 36px;
  flex: 0 0 72px;
}

.groupDropdown {
    flex: unset;
    width: 250px;
    min-width: 140px;

    button {
        svg {
            padding-left: 16px;
        }
    }

    /* ===== Scrollbar CSS ===== */
    & > div:last-child {
        border-radius: 8px 0px 0px 8px;
        @include insightsScrollbar;
    }


}
