.rowMessageContainer {
  color: var(--whoop-color-strain-blue);
  height: 28px;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--whoop-color-strain-blue);
  }
}

