.moreActionsContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  svg {
    fill: var(--whoop-color-black-alpha-500);
  }
  button {
    width: 32px;
    height: 32px;
    border-radius: 0;
    padding: 0;
    margin: 24px 10px;
    &:not([disabled]):before {
      border-radius: 0;
    }
  }
}

.menuList {
  li {
    font-family: var(--whoop-font-family-normal);
    text-transform: capitalize
  }
  .removeMember {
    color: var(--whoop-color-red);
  }
  .hubProfile {
    color: black;
  }
}
