@import '@whoop/web-components/dist/typography.mixin';

.rangeGraph {
  height: 320px;
  width: 100%;

  .rangeGraphAxisText {
    fill: var(--whoop-color-white-alpha-500);
    font-size: 11px;
  }
}