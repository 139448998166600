@import '@whoop/web-components/dist/typography.mixin';

.pageBackground {
  color: var(--text-color);
  flex-grow: 1;
}

.headerLoading {
  padding-top: 8px;
  height: 129px;
  padding-bottom: 8px;
}

.rowLoading {
  height: 64px;

  &:not(:last-child) {
    padding-bottom: 8px;
  }
}

.errorMessage {
  // Design calls for 20px but table has 8px border-spacing
  margin-bottom: 12px;
}

.noDataWrapper {
  color: var(--whoop-color-white);

  .accountName {
    @include p3;
    padding-bottom: 40px;
    padding-left: 24px;
  }

  .noData {
    border-radius: 4px;
    background: var(--backgrounds-dark-alt, #1A2227);
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      height: 28px;
      width: 28px;
      padding-bottom: 16px;
    }

    .noDataSubtitle {
      @include p5;
    }
  }
}


