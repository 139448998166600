@import "styles/color.scss";
@import "styles/layout.scss";

.container {
  width: 100%;
  height: 242px;
  position: relative;

  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(84, 103, 116, 0.6) 100%),
    linear-gradient(180deg, #0d1113 0%, rgba(0, 0, 0, 0) 100%), #1a2227;
  z-index: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .titleContainer {
    display: flex;
    flex-direction: column;

    position: relative;
    height: fit-content;
    top: $SPACING-8;
    left: $SPACING-9;

    .title {
      color: $WHITE;
      margin: 0px 0px $SPACING-1 0px;
    }

    .subtext {
      color: $GRAY_700;
      font-size: 17px !important;
    }
  }

  .membershipAssignedContainer {
    display: flex;
    position: relative;
    top: $SPACING-8;
    right: $SPACING-9;

    .labelContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      .label {
        color: $GRAY_700;
      }

      .labelColor {
        background-color: $STRAIN_BLUE;
        height: $SPACING-1;
        width: $SPACING-1;
        border-radius: 50%;
        margin-right: $SPACING-1;
      }
    }

    .sensorCountContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 104px;

      padding: 0 20px 0 44px;
      color: white;

      .subtext {
        color: $GRAY_700;
        margin-top: 8px;
        font-size: 13px !important;
      }
    }
  }
}