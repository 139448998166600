.content {
  width: 80%;
  height: 100%;
  max-width: 1550px;

  p {
    font-family: ProximaNova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
  }

  .itemTitle {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #969696;
  }

  .pageHeader {
    width: 552px;
    margin-bottom: var(--spacing-sm-5);

    h3 {
      margin-bottom: var(--spacing-sm-5);
    }
  }

  .wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .sectionContainer {
      margin: var(--spacing-xs-3) 0;
      width: 40%;

      h2 {
        margin-bottom: var(--spacing-sm-5);
      }

      .infoItem {
        margin-bottom: var(--spacing-sm);
        p {
          margin: var(--spacing-xs) 0;
        }
      }
    }

    .sectionItem {
      display: flex;
      align-items: center;
      margin-top: var(--spacing-md);

      .buttonWrap {
        width: 126px;
        margin-right: var(--spacing-xs-5);
      }
    }
  }
}

.primaryOwner {
  white-space: nowrap;
}

.primaryOwnerEmail {
  color: #757575;
  margin-left: 4px;
}
