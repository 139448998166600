.buttonContainer {
  height: 44px;
  max-width: 100%;
  min-width: max-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  border: 1px solid var(--color-gray-600);
  border-radius: 8px;

  text-decoration: none;
  background-color: transparent;

  padding: 0px 12px;
  cursor: pointer;

  .largerAvatarSize,
  .avatarSize {
    padding: 6px 0px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

