.pieChartContainer {
  display: flex;
  flex-direction: row;
  gap: 16px; 
  align-items: center;

  .pieChart {
    width: 88px;
    height: 88px;
  }
}